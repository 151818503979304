import { t } from "i18next";
import { TopToolbar, useGetIdentity, useResourceContext } from "react-admin";

export const MyTopToolbar = (props) => {
    const resource = useResourceContext();
    const user = useGetIdentity();
    return (
        <TopToolbar {...props} className="my-top-toolbar">
            <div className="page-title">
                {t(`layout.${resource}`)}
                <span className="plant-name">{user.identity && user.identity.plant.name}</span>
            </div>
            <div className="actions">
                {props.children}
            </div>
        </TopToolbar>
    )
}