import Card from '@material-ui/core/Card';
import { t } from 'i18next';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
    Create,
    Filter,
    ReferenceField,
    ReferenceInput,
    SaveContextProvider,
    SearchInput,
    SelectInput,
    SimpleForm,
    TopToolbar,
    isRequired,
    useGetIdentity,
    useGetList,
    useGetOne,
    useListContext,
    useNotify,
    useRecordContext,
    useUpdate
} from 'react-admin';
import { LOCATION_TYPES } from '../constants';
import { arrayToChoices } from '../utils';
import { PageHeader } from './PageHeader';
import { RangeLimiter } from './RangeLimiter';
import {
    AddButton,
    CSVExportButton,
    EditButton,
    MyDeleteButton,
    MySaveButton,
    ViewButton
} from './react-admin-components/Buttons';
import { MyDialog } from './react-admin-components/Dialog';
import { MyImageInput, MyTextInput } from './react-admin-components/Inputs';
import MyList from './react-admin-components/MyList';
import { MyTopToolbar } from './react-admin-components/MyTopToolbar';
import { MyTextField, TextFieldImage, TextFieldPink, TextFieldTerciary } from './react-admin-components/TextFields';

const MaterialActions = ({ permissions, ...props }) => {
    const { filterValues } = useListContext(props);
    // Specific export for EPI category
    let filteredEPICategory = false;
    Object.values(props.categories).forEach(category => {
        if (filterValues.categoryId && filterValues.categoryId === category.id && category.name === 'EPI')
            filteredEPICategory = true;
    });

    return (
        <MyTopToolbar {...props}>
            <CSVExportButton
                {...props}
                exporterDatas={Object.values(props.data)}
                exportFilename={t('layout.materials')}
                exportFormatFunction={(data, additionalData) =>
                    formatMaterialForExport(data, additionalData, filteredEPICategory)
                }
            />
            {(permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SUPER_ADMIN') && (
                <AddButton label={t('materials.create-category')} onClick={() => props.addCategoryFunc()} />
            )}
            <AddButton label={t('materials.create')} onClick={() => props.addMaterialFunc()} />
        </MyTopToolbar>
    );
};

const formatMaterialForExport = (material, _, filteredEPICategory) => {
    let formatedMaterial = {
        [t('materials.material')]: material.name,
        [t('materials.category')]: material.category && material.category.name,
        [t('materials.quantity')]: material.quantity ? material.quantity : '0'
    };

    // Specific export for EPI category
    // TODO: Maybe refactor in a specific module for EPI
    if (filteredEPICategory) {
        formatedMaterial['EPI'] = '-';
        formatedMaterial['DOTATION (EPI)'] = '-';

        if (material.locations.length > 0) {
            material.locations.forEach(location => {
                formatedMaterial[location.name] = location.LocationMaterial.quantity.toString();
            });
        }
    }

    return formatedMaterial;
};

const MaterialFilters = props => {
    const { categories } = props;
    return (
        <Filter {...props} className='my-filters'>
            <SearchInput source='search' alwaysOn className='my-search-field' placeholder={t('materials.search')} />
            <RangeLimiter alwaysOn />
            <SelectInput
                label={t('materials.category')}
                source='categoryId'
                className='my-filter end'
                alwaysOn
                choices={categories.ids.map(id => {
                    return { id: id, name: categories.data[id].name };
                })}
            />
        </Filter>
    );
};

export const MaterialList = ({ permissions, ...props }) => {
    const { identity, loading } = useGetIdentity();
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editRecord, setEditRecord] = useState(false);
    const [openCreateCategory, setOpenCreateCategory] = useState(false);
    const categories = useGetList('materialCategories');

    if (!categories.loaded || loading) return null;
    return (
        <Card>
            <MyList
                {...props}
                sort={{ field: 'name', order: 'ASC' }}
                filters={<MaterialFilters categories={categories} />}
                actions={
                    <MaterialActions
                        {...props}
                        addMaterialFunc={() => setOpenCreate(true)}
                        addCategoryFunc={() => setOpenCreateCategory(true)}
                        permissions={permissions}
                        categories={categories.data}
                    />
                }
                emptyDatagrid={t('layout.no-results')}
                empty={false}
            >
                <TextFieldImage source='name' label={t('materials.name')} sortable bold />
                <ReferenceField
                    source='categoryId'
                    reference='materialCategories'
                    label={t('materials.category')}
                    sortable
                    link={false}
                >
                    <TextFieldPink source='name' />
                </ReferenceField>
                <TextFieldTerciary source='quantity' label={t('materials.quantity')} sortable />
                {permissions === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceField source='plantId' reference='plants' sortable label={t('layout.plant')} link={false}>
                        <MyTextField source='name' />
                    </ReferenceField>
                )}
                <EditButton
                    label={t('layout.actions')}
                    onClick={record => {
                        setOpenEdit(true);
                        setEditRecord(record);
                    }}
                    className='action-field'
                />
                <ViewButton {...props} className='action-field' />
                <MyDeleteButton
                    {...props}
                    confirmTitle={t('materials.delete')}
                    titleAttribute='name'
                    confirmContent={t('layout.confirm-action')}
                    className='action-field'
                />
            </MyList>
            <MyDialog open={openCreate} setOpen={() => setOpenCreate()} title={t('materials.add-material')} closeIcon>
                <MaterialCreate
                    {...props}
                    basePath={props.basePath}
                    resource={props.resource}
                    closeDialog={() => setOpenCreate(false)}
                    permissions={permissions}
                    identity={identity}
                />
            </MyDialog>
            <MyDialog open={openEdit} setOpen={() => setOpenEdit()} title={t('materials.edit-material')} closeIcon>
                <MaterialEdit
                    {...props}
                    basePath={props.basePath}
                    resource={props.resource}
                    record={editRecord}
                    closeDialog={() => setOpenEdit(false)}
                    permissions={permissions}
                    identity={identity}
                />
            </MyDialog>
            <MyDialog
                open={openCreateCategory}
                setOpen={() => setOpenCreateCategory()}
                title={t('materials.add-category')}
                closeIcon
            >
                <MaterialCategoryCreate
                    {...props}
                    basePath={'/materialCategories'}
                    resource='materialCategories'
                    closeDialog={() => setOpenCreateCategory(false)}
                />
            </MyDialog>
        </Card>
    );
};

const MaterialCreate = ({ permissions, ...props }) => {
    const notify = useNotify();
    const { identity } = props;
    const categories = useGetList('materialCategories');
    if (!categories.loaded) return null;
    return (
        <Create
            {...props}
            className='create-form'
            onSuccess={() => {
                props.closeDialog();
                notify(t('materials.created-material'), { undoable: false });
                window.location.reload();
            }}
            onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
        >
            <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />} submitOnEnter={false}>
                <MyTextInput
                    source='name'
                    labelUnderInput
                    label={t('materials.reference')}
                    placeholder={t('materials.material-name')}
                    validate={isRequired()}
                />
                <ReferenceInput
                    source='categoryId'
                    reference='materialCategories'
                    className='input select label-under-input no-margin-bottom'
                    validate={isRequired()}
                    label={t('materials.category')}
                >
                    <SelectInput
                        source='name'
                        choices={categories.ids.map(id => {
                            return { id: id, name: categories.data[id].name };
                        })}
                    />
                </ReferenceInput>
                {identity.role === 'ROLE_SUPER_ADMIN' && (
                    <div className='row plant-row'>
                        <ReferenceInput
                            source='plantId'
                            reference='plants'
                            className='input select label-under-input no-margin-bottom'
                            label={t('layout.plant')}
                        >
                            <SelectInput optionText='name' />
                        </ReferenceInput>
                    </div>
                )}
            </SimpleForm>
        </Create>
    );
};

const MaterialEdit = ({ permissions, ...props }) => {
    const { identity } = props;
    const categories = useGetList('materialCategories');
    const [update, { loading }] = useUpdate();
    const notify = useNotify();

    const handleSave = useCallback(
        async values => {
            update('materials', props.record.id, values, identity, {
                onSuccess: () => {
                    props.closeDialog();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                    window.location.reload();
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [notify, update, identity, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (loading || !categories.loaded) return null;

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                record={props.record}
                save={handleSave}
            >
                <MyTextInput
                    source='name'
                    labelUnderInput
                    label={t('materials.reference')}
                    placeholder={t('materials.material-name')}
                    validate={isRequired()}
                />
                <MyImageInput source='image' label='' />
                {permissions === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceInput
                        source='plantId'
                        reference='plants'
                        className='input select label-under-input no-margin-bottom'
                        label={t('layout.plant')}
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>
                )}
                <div className='row plant-row'>
                    <ReferenceInput
                        source='categoryId'
                        reference='materialCategories'
                        className='input select label-under-input no-margin-bottom'
                        validate={isRequired()}
                        label={t('materials.category')}
                    >
                        <SelectInput
                            source='name'
                            choices={categories.ids.map(id => {
                                return { id: id, name: categories.data[id].name };
                            })}
                        />
                    </ReferenceInput>
                </div>
            </SimpleForm>
        </SaveContextProvider>
    );
};

const MaterialCategoryCreate = ({ permissions, ...props }) => {
    const notify = useNotify();
    const { identity, loading } = useGetIdentity();
    if (loading) return null;

    return (
        <div>
            <Create
                {...props}
                className='create-form'
                onSuccess={() => {
                    props.closeDialog();
                    notify(t('materials.created-category'), { undoable: false });
                    window.location.reload();
                }}
                onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
            >
                <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />} submitOnEnter={false}>
                    <MyTextInput
                        source='name'
                        labelUnderInput
                        label={t('materials.category-name')}
                        placeholder={t('materials.category-name')}
                        validate={isRequired()}
                    />
                    {identity.role === 'ROLE_SUPER_ADMIN' && (
                        <div className='row plant-row'>
                            <ReferenceInput
                                source='plantId'
                                reference='plants'
                                className='input select label-under-input no-margin-bottom'
                                label={t('layout.plant')}
                            >
                                <SelectInput optionText='name' />
                            </ReferenceInput>
                        </div>
                    )}
                </SimpleForm>
            </Create>
            <Card className='margin-top'>
                <MyList
                    {...props}
                    emptyDatagrid={t('layout.no-results')}
                    empty={false}
                    actions={null}
                    isRowSelectable={() => {
                        return false;
                    }}
                    perPage={10000}
                >
                    <TextFieldPink source='name' label={t('materials.name')} bold />
                    {permissions === 'ROLE_SUPER_ADMIN' && (
                        <ReferenceField
                            source='plantId'
                            reference='plants'
                            sortable
                            label={t('layout.plant')}
                            link={false}
                        >
                            <MyTextField source='name' />
                        </ReferenceField>
                    )}
                    <MyDeleteButton
                        {...props}
                        label={t('layout.actions')}
                        confirmTitle={t('materials.delete')}
                        titleAttribute='name'
                        confirmContent={t('layout.confirm-action')}
                        className='action-field'
                        redirect='/materials'
                    />
                </MyList>
            </Card>
        </div>
    );
};

// ====================================================================================================
//           SINGLE MATERIAL
// ====================================================================================================

const SingleMaterialActions = props => {
    const material = props.material;

    return (
        <TopToolbar {...props} className='single-line-actions'>
            <CSVExportButton
                {...props}
                exporterDatas={material.locations}
                exporterAdditionalData={{ material }}
                exportFilename={`${material.name}-${t('layout.materials')}`}
                exportFormatFunction={formatLocationForExport}
            />
        </TopToolbar>
    );
};

const formatLocationForExport = (location, { material }) => {
    if (!location.LocationMaterial) material = location.materials.find(m => m.id === material.id);

    return {
        [t('locations.location')]: location.name,
        [t('locations.location-type')]: t(`locations.${location.type}`),
        [t('locations.updated-at')]: location.updatedAt,
        [t('locations.filling-level')]: t(`locations.${location.filling}`),
        [t('locations.comment')]: location.comment,
        [t('materials.material')]: material.name,
        [t('materials.quantity')]: material.LocationMaterial
            ? material.LocationMaterial.quantity
            : location.LocationMaterial.quantity
    };
};

const SingleMaterialFilters = props => {
    return (
        <Filter {...props} className='my-filters single-line'>
            <div className='material-name' alwaysOn>
                {props.name && props.name}
            </div>
            <SelectInput
                source='type'
                alwaysOn
                className='my-filter'
                label={t('locations.location')}
                choices={arrayToChoices(LOCATION_TYPES.list_choices, 'locations')}
            />
        </Filter>
    );
};

const MaterialImageField = props => <TextFieldImage source='name' record={props.material} />;

const MaterialQuantityField = props => {
    const record = useRecordContext(props);
    let currentMaterial = record.materials.length > 0 && record.materials.find(l => l.id === props.material.id);
    return <span className='text-field'>{currentMaterial && currentMaterial.LocationMaterial.quantity}</span>;
};

export const MaterialShow = ({ permissions, ...props }) => {
    const { data } = useGetOne(props.resource, props.id);
    const [material, setMaterial] = useState();

    if (!material && data) setMaterial(data);

    return (
        <div className='single-entity-page'>
            {material && (
                <>
                    <PageHeader
                        {...props}
                        breadcrumbs={[
                            { label: t(`layout.materials`), path: props.basePath },
                            material.category
                                ? {
                                      label: material.category.name,
                                      path: props.basePath + `?filter=%7B"categoryId"%3A"${material.category.id}"%7D`
                                  }
                                : null,
                            { label: material.name }
                        ]}
                    />
                    <MyList
                        {...props}
                        resource='locations'
                        filter={{ '$materials%id$': material.id }}
                        filters={<SingleMaterialFilters {...props} name={material.name} />}
                        actions={
                            <SingleMaterialActions
                                {...props}
                                resource='locations'
                                filter={{ '$materials%id$': material.id }}
                                filters={<SingleMaterialFilters {...props} name={material.name} />}
                                material={material}
                            />
                        }
                        emptyDatagrid={t('materials.no-locations')}
                        empty={false}
                    >
                        <TextFieldPink
                            source='name'
                            sortable
                            label={t('locations.location')}
                            textTransform='uppercase'
                        />
                        <MaterialImageField material={material} label={t('layout.materials')} />
                        <MaterialQuantityField
                            source={'locations.LocationMaterial.quantity'}
                            material={material}
                            sortable
                            label={t('materials.quantity')}
                        />
                        <ViewButton res='locations' label={t('layout.actions')} className='action-field' />
                    </MyList>
                </>
            )}
        </div>
    );
};
