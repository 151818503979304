import { Button, TableCell, TableHead, TableRow } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useState } from 'react';
import {
    Datagrid,
    DatagridBody,
    List,
    Pagination,
    Toolbar,
    useListContext,
    useListSortContext,
    useUnselectAll
} from 'react-admin';
import '../../style/components/react-admin-components/my-list.scss';
import { EmptySingleEntity } from './EmptySingleEntity';

const MyDatagridHeader = props => {
    // currentSort is an object { field, order } containing the current sort
    // setSort is a callback (field, order) => void allowing to change the sort field and order
    const { currentSort, setSort } = useListSortContext();
    const inverseOrder = sort => (sort === 'ASC' ? 'DESC' : 'ASC');

    const { children, isRowSelectable, sorter } = props;
    const { data, ids, onSelect, selectedIds } = useListContext(props);
    const actionButtons = ['ViewButton', 'MyDeleteButton', 'UpdateDefectiveMaterialButton', 'EditButton', 'Icon'];

    const handleChangeSort = event => {
        const field = event.currentTarget.dataset.sort;
        const newOrder = field === currentSort.field ? inverseOrder(currentSort.order) : 'ASC';
        setSort(field, newOrder);
        sorter({ field: field, order: newOrder });
    };

    const handleSelectAll = useCallback(
        event => {
            onSelect(
                event.target.checked
                    ? ids
                          .filter(id => (isRowSelectable ? isRowSelectable(data[id]) : true))
                          .concat(selectedIds.filter(id => !ids.includes(id)))
                    : []
            );
        },
        [data, ids, onSelect, isRowSelectable, selectedIds]
    );

    const selectableIds = isRowSelectable ? ids.filter(id => isRowSelectable(data[id])) : ids;

    return (
        <TableHead className='datagrid-header'>
            <TableRow>
                {(isRowSelectable ? isRowSelectable() : true) && (
                    <TableCell className='checkbox-cell' width={'57px'}>
                        <Checkbox
                            checked={
                                selectedIds.length > 0 &&
                                selectableIds.length > 0 &&
                                selectableIds.every(id => selectedIds.includes(id))
                            }
                            onChange={handleSelectAll}
                        />
                    </TableCell>
                )}{' '}
                {/* empty cell to account for the select row checkbox in the body */}
                {children.map(child => {
                    if (child.props) {
                        return (
                            <TableCell
                                style={{ width: child.props.width }}
                                onClick={child.props.sortable && handleChangeSort}
                                // store the sort field in the element dataset to avoid creating a new click handler for each item (better for performance)
                                data-sort={child.props.source}
                                key={child.props.source}
                                className={
                                    child &&
                                    (actionButtons.includes(child.type.name) ||
                                        child.props.className === 'action-field')
                                        ? 'action-button-column'
                                        : 'column'
                                }
                            >
                                <div className='table-title'>
                                    <span>{child.props.label}</span>
                                    {child.props.sortable && (
                                        <img
                                            src={
                                                currentSort.field === child.props.source
                                                    ? currentSort.order === 'ASC'
                                                        ? 'assets/sort-up-icon.svg'
                                                        : 'assets/sort-down-icon.svg'
                                                    : 'assets/sort-alt-icon.svg'
                                            }
                                            alt=''
                                        />
                                    )}
                                </div>
                            </TableCell>
                        );
                    } else {
                        return null;
                    }
                })}
            </TableRow>
        </TableHead>
    );
};

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, selectable, basePath }) => {
    return (
        <TableRow key={id} className='row' isRowSelectable={selectable}>
            {/* first column: selection checkbox */}
            {selectable && (
                <TableCell padding='none' className='checkbox-cell' width={'57px'}>
                    <Checkbox checked={selectable && selected} onClick={event => onToggleItem(id, event)} />
                </TableCell>
            )}
            {/* data columns based on children */}
            {React.Children.map(children, field => {
                if (field) {
                    return (
                        <TableCell key={`${id}-${field.props.source}`}>
                            {React.cloneElement(field, {
                                record,
                                basePath,
                                resource
                            })}
                        </TableCell>
                    );
                }
            })}
        </TableRow>
    );
};

const MyDatagridBody = props => {
    return <DatagridBody {...props} row={<MyDatagridRow />} />;
};

const MyDatagrid = props => {
    const context = useListContext();
    const [sorter, setSorter] = useState();
    context.total = props.useContextData ? props.contextIds.length : context.total ? context.total : 0;
    context.loaded = props.useContextData ? true : context.loading ? false : true;
    const data = props.useContextData ? props.contextData : context.data;
    let ids = props.useContextData ? props.contextIds : context.ids;
    // Filter data when in a datagrid with custom data
    if (sorter && props.manualSorting) {
        const dataArray = Object.keys(data).map(d => data[d]);
        function compare(a, b) {
            const getField = obj => {
                const value = sorter.field.includes('.')
                    ? sorter.field.split('.').reduce((a, b) => a[b], obj)
                    : obj[sorter.field];
                return typeof value === 'string' ? value.toString().toLowerCase() : value;
            };

            if (getField(a) < getField(b)) {
                return sorter.order === 'ASC' ? -1 : 1;
            }
            if (getField(a) > getField(b)) {
                return sorter.order === 'ASC' ? 1 : -1;
            }
            return 0;
        }
        dataArray.sort(compare);
        ids = dataArray.map(d => d.id);
    }

    return (
        <Datagrid
            {...props}
            data={data}
            ids={ids}
            loaded={true}
            // loaded={props.contextIds.length > 0 ? true : context.loaded}
            header={<MyDatagridHeader {...props} sorter={s => setSorter(s)} />}
            body={<MyDatagridBody {...props} data={data} ids={ids} />}
            className={`my-datagrid ${props.isRowSelectable && !props.isRowSelectable() ? 'no-checkbox' : ''}`}
            style={{ tableLayout: 'fixed' }}
            empty={<EmptySingleEntity text={props.emptyDatagrid} />}
        />
    );
};

const Indicator = props => {
    const unselectAll = useUnselectAll();

    return (
        <span
            className={`page-indicator ${props.id === props.currentPage && 'active'}`}
            onClick={() => {
                props.setPage(props.id);
                unselectAll(props.resource);
            }}
        >
            {props.id}
        </span>
    );
};

const MyPostPagination = props => {
    const { page, perPage, total, setPage } = useListContext();
    const unselectAll = useUnselectAll();
    const ttl = props.contextIds.length > 0 ? props.contextIds.length : total;
    const nbPages = Math.ceil(ttl / perPage) || 1;
    const pageIndicators = [];
    pageIndicators.push(<Indicator id={1} currentPage={page} resource={props.resource} setPage={setPage} />);
    page > 4 && pageIndicators.push(<span className='spacer'>...</span>);

    for (let i = Math.max(page - 2, 1); i <= Math.min(page + 2, nbPages); i++) {
        if (i !== 1 && i !== nbPages) {
            pageIndicators.push(<Indicator id={i} currentPage={page} resource={props.resource} setPage={setPage} />);
        }
    }

    page <= Math.max(nbPages - 4, 0) && pageIndicators.push(<span className='spacer'>...</span>);
    pageIndicators.push(<Indicator id={nbPages} currentPage={page} resource={props.resource} setPage={setPage} />);

    return (
        nbPages > 1 && (
            <Pagination
                {...props}
                rowsPerPageOptions={[]}
                className='my-pagination'
                actions={() => (
                    <Toolbar className='my-toolbar'>
                        <Button
                            color='primary'
                            key='prev'
                            onClick={() => {
                                setPage(page - 1);
                                unselectAll(props.resource);
                            }}
                            disabled={page <= 1}
                            className='nav-button'
                        >
                            {t('layout.previous')}
                        </Button>
                        {pageIndicators}
                        <Button
                            color='primary'
                            key='next'
                            onClick={() => {
                                setPage(page + 1);
                                unselectAll(props.resource);
                            }}
                            disabled={page === nbPages}
                            className='nav-button'
                        >
                            {t('layout.next')}
                        </Button>
                    </Toolbar>
                )}
            />
        )
    );
};

const MyList = props => {
    const { data, children } = props;
    let listIds = data ? Object.keys(data) : [];

    return (
        <List {...props} className='my-list' pagination={<MyPostPagination contextData={data} contextIds={listIds} />}>
            <MyDatagrid {...props} contextData={data} contextIds={listIds}>
                {children}
            </MyDatagrid>
        </List>
    );
};

MyList.propTypes = {
    manualSorting: PropTypes.bool
};

export default MyList;
