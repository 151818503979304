import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Confirm, SaveContextProvider, SimpleForm, Toolbar, useDelete, useNotify, useUpdate } from 'react-admin';
import InnerImageZoom from 'react-inner-image-zoom';
import Slider from 'react-slick';

import { Button, Icon, MySaveButton } from './Buttons';
import { MyPasswordInput } from './Inputs';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../style/components/react-admin-components/dialog.scss';

export const MyDialog = props => {
    const { open, setOpen, closeIcon, title, subtitle, children } = props;
    return (
        <Dialog {...props} open={open} className={`modal-container ${closeIcon && 'icon'}`}>
            {closeIcon && (
                <img
                    src='assets/close-icon.svg'
                    alt='Close icon'
                    onClick={() => setOpen(false)}
                    className='close-icon'
                />
            )}
            <span className={`dialog-title ${closeIcon && 'left'}`}>{title}</span>
            {!closeIcon && <span className='dialog-subtitle'>{subtitle}</span>}
            {children}
        </Dialog>
    );
};

MyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    closeIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.object
};

export const EditPasswordDialog = props => {
    const { open, setOpen, user } = props;
    const notify = useNotify();
    const [update, { loading }] = useUpdate();

    const handleSave = useCallback(
        async values => {
            if (!values.password || !values.newPassword) {
                notify(t('users.enter-password'), { undoable: false, type: 'warning' });
                return;
            } else if (values.password !== values.newPassword) {
                notify(t('users.identical-password'), { undoable: false, type: 'warning' });
                return;
            }
            update('users', `${user.id}/password`, { ...values, updatePassword: true }, user, {
                onSuccess: () => {
                    setOpen(false);
                    notify(t('layout.changes-saved'), { undoable: false });
                    window.location.reload();
                },
                onFailure: error => notify(t({ ...error }.body.messageKey), { type: 'warning' })
            });
        },
        [notify, update, user, setOpen]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [handleSave, loading]
    );

    if (loading) return null;

    const toolbar = (
        <Toolbar className='buttons'>
            <Button secondStyle value={t('layout.cancel')} onClick={() => setOpen(false)} type='button' />
            <MySaveButton label={t('layout.validate')} form='modify_password_form' className='no-margin' />
        </Toolbar>
    );

    return (
        <MyDialog
            open={open}
            setOpen={() => setOpen()}
            title={t('users.modify-password')}
            subtitle={t('users.modify-password-subtitle')}
        >
            <SaveContextProvider value={saveContext}>
                <SimpleForm
                    className='modify-password-form'
                    id='modify_password_form'
                    toolbar={toolbar}
                    save={handleSave}
                >
                    <MyPasswordInput source='password' label={t('users.new-password')} id='password' />
                    <MyPasswordInput source='newPassword' label={t('users.confirm')} id='confirm-password' />
                </SimpleForm>
            </SaveContextProvider>
        </MyDialog>
    );
};

const renderImageSlide = (upload, index, deletable = false, handleClick = () => {}) => {
    const img = new Image();
    img.src = process.env.REACT_APP_S3_PUBLIC_URL + upload.key;

    return (
        <div className='image-container' key={index}>
            <InnerImageZoom
                src={process.env.REACT_APP_S3_PUBLIC_URL + upload.key}
                zoomScale={img.width < 500 ? 4 : 0.6} // If image is too small, scale it up. 500 is arbitrary
                imgAttributes={{ className: `movement-image ${img.height < 500 && 'small-image'}` }}
            />
            {deletable && (
                <div className='image-delete-button'>
                    <Icon
                        customClass='icon-button-secondary'
                        icon={<DeleteIcon className='icon-primary' />}
                        onClick={handleClick}
                    />
                </div>
            )}
        </div>
    );
};

export const DefectiveMaterialUploadDialog = props => {
    const { open, setOpen, upload } = props;

    return (
        <Dialog
            {...props}
            open={open}
            setOpen={() => setOpen()}
            onClose={() => setOpen(false)}
            className='modal-container uploads-modal'
        >
            <div className='title'>
                <img
                    src='assets/close-icon.svg'
                    alt='Close icon'
                    onClick={() => setOpen(false)}
                    className='close-icon'
                />
            </div>

            <Slider dots={false} slidesToShow={1} slidesToScroll={1} className='movement-uploads-slider'>
                {upload ? renderImageSlide(upload, 0) : <span>{t('locations.no-photos')}</span>}
            </Slider>
        </Dialog>
    );
};

export const MovementUploadsDialog = props => {
    const [deleteOne, { loading }] = useDelete();
    const [uploads, setUploads] = useState(props.uploads);
    const [currentUpload, setCurrentUpload] = useState(props.uploads.length ? props.uploads[0] : null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const notify = useNotify();

    const { open, setOpen, movement = null, refreshUploads = null } = props;

    React.useEffect(() => {
        if (uploads.length !== props.uploads) {
            setUploads(props.uploads);
            setCurrentUpload(props.uploads.length ? props.uploads[0] : null);
        }
    }, [props.uploads]);

    const date =
        movement &&
        new Date(movement.updatedAt).toLocaleDateString('fr-fr', {
            weekday: 'long',
            day: 'numeric',
            month: 'long'
        });

    const handleClick = () => setOpenConfirmDialog(true);
    const handleDialogClose = () => setOpenConfirmDialog(false);
    const handleConfirm = upload => {
        deleteOne('uploads', upload.id, upload, {
            onSuccess: () => {
                const newUploads = uploads.filter(u => u.id !== upload.id);
                setUploads(newUploads);
                refreshUploads && refreshUploads(newUploads);
                notify(`${t('layout.image-deleted')}`, { undoable: false });
            },
            onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
        });
        setOpenConfirmDialog(false);
    };

    if (loading) return null;

    return (
        <Dialog
            {...props}
            open={open}
            setOpen={() => setOpen()}
            onClose={() => setOpen(false)}
            className='modal-container uploads-modal'
        >
            <div className='title'>
                <span className={`dialog-title left`}>
                    {date ? t('movements.last-movement') + date : t('locations.photos')}
                </span>
                <img
                    src='assets/close-icon.svg'
                    alt='Close icon'
                    onClick={() => setOpen(false)}
                    className='close-icon'
                />
            </div>
            <Slider
                dots={true}
                slidesToShow={1}
                slidesToScroll={1}
                className='movement-uploads-slider'
                beforeChange={(current, next) => setCurrentUpload(uploads[next])}
            >
                {uploads && uploads.length ? (
                    uploads.map((upload, index) => {
                        return renderImageSlide(upload, index, true, handleClick);
                    })
                ) : (
                    <span>{t('locations.no-photos')}</span>
                )}
            </Slider>

            {currentUpload && (
                <Confirm
                    isOpen={openConfirmDialog}
                    title={t('layout.deletion')}
                    content={t('locations.confirm-delete-upload')}
                    cancel={t('layout.cancel')}
                    confirm={t('uploads.delete')}
                    confirmColor='warning'
                    onConfirm={() => handleConfirm(currentUpload)}
                    onClose={handleDialogClose}
                />
            )}
        </Dialog>
    );
};

export const MovementsDialog = props => {
    const { open, setOpen, movements } = props;
    return (
        <Dialog
            {...props}
            open={open}
            setOpen={() => setOpen()}
            onClose={() => setOpen(false)}
            className='modal-container movements-modal'
        >
            <img src='assets/close-icon.svg' alt='Close icon' onClick={() => setOpen(false)} className='close-icon' />
            {movements.map(movement => {
                const date = new Date(movement.updatedAt).toLocaleDateString('fr-fr', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long'
                });
                return (
                    <a className='movement-row' href={`/#/movements/${movement.id}/show`}>
                        <span className='title date'>{date}</span>
                        <div className='row'>
                            <div className='column location'>
                                <span className='title'>{t('movements.origin')}</span>
                                <span className='location-name'>{movement.origin.name}</span>
                            </div>
                            <div className='column'>
                                <img src='assets/container-illustration.svg' alt='container' className='illustration' />
                            </div>
                            <div className='column location'>
                                <span className='title'>{t('movements.destination')}</span>
                                <span className='location-name'>{movement.destination.name}</span>
                            </div>
                        </div>
                    </a>
                );
            })}
        </Dialog>
    );
};

MovementsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    movements: PropTypes.array.isRequired
};
