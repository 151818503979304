import { t } from 'i18next';
import decodeJwt from 'jwt-decode';
import { useNotify } from 'react-admin';
import * as Sentry from "@sentry/react";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({ badge: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, role }) => {
                if (role === 'ROLE_MANAGER' || role === "ROLE_ADMIN" || role === "ROLE_SUPER_ADMIN") {
                    const decodedToken = decodeJwt(token);
                    localStorage.setItem('user', decodedToken.id);
                    Sentry.setUser({ id: decodedToken.id });
                } else {
                    useNotify()
                }
            });
    },
    logout: () => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/logout`, { credentials: 'include' });
        localStorage.setItem('user', null);
        return fetch(request).then(() => { return Promise.resolve() })
    },
    checkAuth: () => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/login_check`, { credentials: 'include' });
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject({ message: t('login.please-login') });
            }
            return Promise.resolve();
        });
    },
    getIdentity: async () => await getUser(),
    getPermissions: async () => {
        const user = await getUser();
        return user.role ? Promise.resolve(user.role) : Promise.reject();
    },
    checkError: (error) => {
        const err = { ...error };
        if (err.status === 401 || err.status === 403) {
            return Promise.reject();
        }
        if (err.body.messageKey) {
            // other error code (404, 500, etc): no need to log out
            return Promise.resolve();
        }
    }
};

const getUser = () => {
    const user = localStorage.getItem('user');

    if (user) {
        const request = new Request(`${process.env.REACT_APP_API_URL}/users/${user}`, { credentials: 'include' });
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        }).then(data => {
            return Promise.resolve(data);
        });
    }

    return Promise.resolve({});
}

export default authProvider;