import * as React from "react";
import PropTypes from 'prop-types';
import '../../style/components/react-admin-components/inputs.scss';
import { ReferenceInput, AutocompleteInput, ImageField, ImageInput, NumberInput, PasswordInput, TextInput } from "react-admin";

export const MyTextInput = props => {
    const { source, label, labelUnderInput, type } = props;
    switch (type) {
        case 'number':
            return <NumberInput {...props} source={source} label={label} className={`input ${labelUnderInput && "label-under-input"} ${props.noArrows && "no-arrows"}`} />

        default:
            return <TextInput {...props} source={source} label={label} className={`input ${labelUnderInput && "label-under-input"}`} />
    }
}

MyTextInput.propTypes = {
    source: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelUnderInput: PropTypes.bool,
    type: PropTypes.string
}

export const MyAutoCompleteInput = props => {
    const { label, source, reference, validate, matchSuggestion, perPage } = props;

    return (
        <div className="auto-complete-input" >
            <div className="icon">
                <img className="search-icon" src="/assets/search-icon.svg" alt="Search icon" />
            </div>
            <ReferenceInput className="input label-under-input" label={label} source={source} reference={reference} perPage={perPage} filterToQuery={searchText => ({ search: `%${searchText}%` })}>
                <AutocompleteInput validate={validate} matchSuggestion={matchSuggestion} />
            </ReferenceInput>
        </div>
    )
}

MyAutoCompleteInput.propTypes = {
    source: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    label: PropTypes.string
}

export const MyImageInput = props => {
    const { source, label } = props;
    const inputPlaceHolder = (
        <div className="image-input">
            <img src="assets/add-image-icon.svg" alt="add icon" />
        </div>
    )

    return (
        <ImageInput source={source} label={label} accept=".png,.jpg,.jpeg" maxSize={5000000} placeholder={inputPlaceHolder} className="my-image-input" >
            <ImageField source="src" title="img" />
        </ImageInput>
    )
}

export const MyPasswordInput = props => {
    const { source, label, id, max272, labelUnderInput } = props
    return (
        <PasswordInput {...props} source={source} className={`input input-password ${max272 && "max-272"} ${labelUnderInput && "label-under-input"}`} label={label} id={id} />
    )
}