import * as React from "react";
import {
    Create,
    SimpleForm,
    ReferenceInput,
    TextInput,
    ReferenceField,
    useRecordContext,
    Filter,
    SearchInput,
    SelectInput,
    useGetOne,
    Edit,
    useGetIdentity,
    useNotify,
    isRequired,
} from "react-admin";
import { t } from "i18next";
import { MyTextField, TextFieldTerciary } from "./react-admin-components/TextFields";
import MyList from "./react-admin-components/MyList";
import { MyTopToolbar } from "./react-admin-components/MyTopToolbar";
import { AddButton, CSVExportButton, ViewButton, ModifyAccount, MySaveButton, MyDeleteButton } from "./react-admin-components/Buttons";
import Card from "@material-ui/core/Card";
import { RangeLimiter } from "./RangeLimiter";
import { arrayToChoices } from "../utils";
import { ROLES } from "../constants";
import { UserPageHeader } from "./PageHeader";
import { useState } from "react";
import { EditPasswordDialog, MyDialog } from "./react-admin-components/Dialog";
import { MyPasswordInput, MyTextInput } from "./react-admin-components/Inputs";
import '../style/components/profile.scss';

const UserActions = (props) => {
    return (
        <MyTopToolbar {...props} >
            <CSVExportButton 
                {...props} 
                exporterDatas={Object.values(props.data)} 
                exportFilename={t('users.users')}
                exportFormatFunction={formatUserForExport}
            />
            <AddButton label={t('users.add')} onClick={() => props.addUserFunc()} />
        </MyTopToolbar>
    )
}

const formatUserForExport = (user, _) => {
    return {
        [t('users.badge-number')]: user.badge,
        [t('users.firstname')]: user.firstname,
        [t('users.lastname')]: user.lastname,
        [t('users.role')]: t(`users.${user.role}`),
        [t('users.email')]: user.email,
    }
}

const UserFilters = (props) => (
    <Filter {...props} className="my-filters">
        <SearchInput placeholder={t('users.search')} source="search" alwaysOn className="my-search-field" />
        <RangeLimiter alwaysOn />
        <SelectInput source="role" alwaysOn className="my-filter end" choices={arrayToChoices(ROLES.ROLE_ADMIN, "users")} label={t('users.occupied-role')} />
    </Filter>
);

const BadgeTextField = (props) => {
    const record = useRecordContext(props)
    const { source } = props;
    return <span className="text-field pink-text-field">#{record[source]}</span>;
}

const RoleTextField = (props) => {
    const record = useRecordContext(props)
    const { source } = props;
    return <span className="text-field terciary-text-field">{t(`users.${record[source]}`)}</span>;
}

export const UserList = ({ permissions, ...props }) => {
    const { identity, loaded } = useGetIdentity();
    const [openCreate, setOpenCreate] = useState();
    if (!loaded) return null;
    return (
        <Card>
            <MyList {...props} filters={<UserFilters />} actions={<UserActions {...props} addUserFunc={() => setOpenCreate(true)} />} emptyDatagrid={t('layout.no-results')} empty={false} manualSorting>
                <BadgeTextField source="badge" label={t('users.badge-number')} sortable />
                <TextFieldTerciary source="firstname" label={t('users.firstname')} sortable />
                <TextFieldTerciary source="lastname" label={t('users.lastname')} sortable />
                <RoleTextField source="role" label={t('users.occupied-role')} sortable />
                {identity.role === 'ROLE_SUPER_ADMIN' &&
                    <ReferenceField source="plantId" reference="plants" label={t('layout.plant')} sortable link={false}>
                        <MyTextField source="name" />
                    </ReferenceField>
                }
                <ViewButton label={t('layout.actions')} className="action-field" />
                <MyDeleteButton {...props} confirmTitle={t('users.delete')} titleAttribute="lastname" confirmContent={t('layout.confirm-action')} className="action-field" />
            </MyList>
            <MyDialog open={openCreate} setOpen={() => setOpenCreate()} title={t('users.add')} closeIcon>
                <UserCreate {...props} basePath={props.basePath} resource={props.resource} closeDialog={() => setOpenCreate(false)} />
            </MyDialog>
        </Card>
    )
};

const UserCreate = ({ permissions, ...props }) => {
    const notify = useNotify();
    const { identity, loading } = useGetIdentity();
    if (loading) return null;
    return (
        <Create
            {...props}
            className="create-form user-create"
            onSuccess={() => {
                props.closeDialog()
                notify(t('users.created'), { undoable: false });
            }}
            onFailure={(error) => {
                const err = { ...error };
                notify(t(err.body.messageKey), { type: "warning" })
            }}
        >
            <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />} submitOnEnter={false} >
                <div className="row">
                    <MyTextInput type="number" source="badge" labelUnderInput label={t('users.badge-number')} validate={isRequired()} noArrows />
                    <SelectInput source="role" choices={arrayToChoices(ROLES[identity.role], "users")} className="input select" validate={isRequired()} label={t('users.role')} />
                </div>
                <div className="row">
                    <MyTextInput source="firstname" labelUnderInput label={t('users.firstname')} validate={isRequired()} />
                    <MyTextInput source="lastname" labelUnderInput label={t('users.lastname')} validate={isRequired()} />
                </div>
                <MyPasswordInput source="password" label={t('users.password')} validate={isRequired()} labelUnderInput />
                {identity.role === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceInput source="plantId" reference="plants" className="input select" label={t('layout.plant')}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}
            </SimpleForm>
        </Create>
    )
};

// ====================================================================================================
//           SINGLE MATERIAL
// ====================================================================================================

export const RoleTextInput = (props) => {
    const record = useRecordContext(props);
    return (
        <div className="input disabled-input">
            <label><span>{t('users.role')}</span></label>
            <input disabled value={t(`users.${record.role}`)} />
        </div>
    );
}

export const UserShow = ({ permissions, ...props }) => {
    const notify = useNotify();
    const [open, setOpen] = useState();
    const [openCreate, setOpenCreate] = useState();
    const { identity, loaded } = useGetIdentity();
    const { data, loading } = useGetOne(props.resource, props.id);

    if (loading || !loaded) return null;

    const canDo = (identity.role === "ROLE_SUPER_ADMIN" && data.role !== "ROLE_SUPER_ADMIN") ||
        (identity.role === "ROLE_ADMIN" && (data.role === "ROLE_MANAGER" || data.role === "ROLE_AGENT")) ||
        (identity.role === "ROLE_MANAGER" && data.role === "ROLE_AGENT");
    return (
        <Card id="profile" className="single-entity-page agent-page">
            <UserPageHeader {...props} breadcrumbs={[{ label: t(`layout.users`), path: props.basePath }, { label: `${data.firstname} ${data.lastname}` }]} noBackground onAddClick={() => setOpenCreate(true)} />
            <Edit {...props} hasShow={false} onSuccess={() => { notify(t('users.updated'), { undoable: true }); }} >
                <SimpleForm className="user-profile" toolbar={canDo ? <ModifyAccount /> : null}>
                    <TextInput source="badge" validate={isRequired()} className={`input ${!canDo && "disabled-input"}`} disabled={!canDo} label={t('users.badge-number')} />
                    <TextInput source="firstname" validate={isRequired()} className={`input ${!canDo && "disabled-input"}`} disabled={!canDo} label={t('users.firstname')} />
                    <TextInput source="lastname" validate={isRequired()} className={`input ${!canDo && "disabled-input"}`} disabled={!canDo} label={t('users.lastname')} />
                    {canDo ?
                        <SelectInput source="role" validate={isRequired()} choices={arrayToChoices(ROLES[identity.role], "users")} className="input select" label={t('users.role')} />
                        :
                        <RoleTextInput validate={isRequired()} label={t('users.occupied-role')} />
                    }
                    {identity.role === 'ROLE_SUPER_ADMIN' &&
                        <ReferenceInput source="plantId" reference="plants" className={`input select ${!canDo && "disabled-input"}`} disabled={!canDo} label={t('layout.plant')}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    }
                </SimpleForm>
            </Edit>
            {canDo ?
                <div className="reset-container">
                    <button className="reset-password-button" onClick={() => setOpen(true)} >{t('users.reset-password')}</button>
                </div> : null
            }
            <EditPasswordDialog open={open} setOpen={() => setOpen()} user={data} />
            <MyDialog open={openCreate} setOpen={() => setOpenCreate()} title={t('users.add')} closeIcon>
                <UserCreate {...props} basePath={props.basePath} resource={props.resource} closeDialog={() => setOpenCreate(false)} />
            </MyDialog>
        </Card>
    )
};