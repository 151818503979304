import Card from '@material-ui/core/Card';
import { t } from 'i18next';
import * as React from 'react';
import { useState } from 'react';
import {
    DateField,
    DateInput,
    Filter,
    FunctionField,
    ReferenceField,
    SearchInput,
    SelectInput,
    TextField,
    useGetList,
    useGetOne,
    useListContext,
    useRecordContext
} from 'react-admin';
import '../style/components/movements.scss';
import { PageHeader } from './PageHeader';
import { RangeLimiter } from './RangeLimiter';
import { CSVExportButton, ViewButton } from './react-admin-components/Buttons';
import { MovementUploadsDialog } from './react-admin-components/Dialog';
import MyList from './react-admin-components/MyList';
import { MyTopToolbar } from './react-admin-components/MyTopToolbar';
import { TextFieldImage, TextFieldPink } from './react-admin-components/TextFields';

const MovementActions = props => {
    return (
        <MyTopToolbar {...props}>
            <CSVExportButton
                {...props}
                exporterDatas={Object.values(props.data)}
                exportFilename={t('layout.movements')}
                exportFormatFunction={formatMovementForExport}
            />
        </MyTopToolbar>
    );
};

const formatMovementForExport = (movement, _) => {
    return {
        [t('movements.date')]: movement.updatedAt,
        [t('movements.origin')]: movement.origin.name,
        [t('movements.destination')]: movement.destination.name,
        [t('movements.agent')]: `${movement.user.firstname} ${movement.user.lastname}`,
        [t('layout.movements')]: movement.materials.map(material => {
            return {
                [t('materials.material')]: material.name,
                [t('materials.quantity')]: material.MovementMaterial.quantity
            };
        })
    };
};

const DateBetweenInput = props => {
    const { source } = props;
    return (
        <span className='date-between-input'>
            <DateInput source={`${source}_after`} label={`Après`} className='my-filter date-input' />
            <DateInput source={`${source}_before`} label={`Avant`} className='my-filter date-input' />
        </span>
    );
};

const SelectLocation = props => {
    const locations = useGetList('locations', { page: 1, perPage: -1 });
    const { filterValues, setFilters } = useListContext(props);
    if (!locations.loaded) return null;
    const choices = [
        { id: '', name: '' },
        ...locations.ids.map(id => {
            return { id: id, name: locations.data[id].name };
        })
    ];

    return (
        <SelectInput
            source='originId'
            label={t('locations.location')}
            onChange={e => setFilters({ ...filterValues, originId: e.target.value, destinationId: e.target.value })}
            className='my-filter'
            choices={choices}
        />
    );
};

const MovementsFilters = props => (
    <Filter {...props} className='my-filters'>
        <SearchInput placeholder={t('locations.search')} source='search' alwaysOn className='my-search-field' />
        <RangeLimiter alwaysOn />
        <SelectLocation {...props} source='originId' alwaysOn />
        <DateBetweenInput label={t('layout.date')} source='updatedAt' className='my-filter end' alwaysOn />
    </Filter>
);

const LocationField = props => <TextFieldPink {...props} record={props.record[props.target]} source='name' />;

export const MovementList = props => (
    <MyList
        {...props}
        filters={<MovementsFilters {...props} />}
        filter={{ finished: true }}
        actions={<MovementActions />}
        emptyDatagrid={t('layout.no-results')}
        empty={false}
        sort={{ field: 'updatedAt', order: 'DESC' }}
    >
        <TextField width='20%' source='number' label={t('movements.index')} />
        <LocationField target='origin' source='origin.name' uppercase label={t('movements.origin')} sortable />
        <LocationField
            target='destination'
            source='destination.name'
            uppercase
            label={t('movements.destination')}
            sortable
        />
        <FunctionField
            label={t('movements.agent')}
            reference='users'
            render={record => record && `${record.user.firstname} ${record.user.lastname}`}
            className='text-field terciary-text-field'
            sortable
            source='user.firstname'
        />
        <DateField source='updatedAt' label={t('movements.date')} sortable className='text-field terciary-text-field' />
        <ViewButton label={t('layout.actions')} className='action-field' />
    </MyList>
);

// ====================================================================================================
//           SINGLE MOVEMENT
// ====================================================================================================

const MaterialQuantityField = props => {
    const record = useRecordContext(props);
    let currentMovement =
        record.movements && record.movements.length > 0 && record.movements.find(l => l.id === props.movement.id);
    return <span className='text-field'>{currentMovement && currentMovement.MovementMaterial.quantity}</span>;
};

export const MovementShow = props => {
    const { data } = useGetOne(props.resource, props.id);
    const [movement, setMovement] = useState();
    const [openOriginPhotos, setOpenOriginPhotos] = useState(false);
    const [openDestinationPhotos, setOpenDestinationPhotos] = useState(false);

    if (!movement && data) setMovement(data);

    let date, dateNb, month, year, originUploads, destinationUploads;
    if (movement) {
        date = new Date(movement.updatedAt);
        dateNb = date.getDate().toString().padStart(2, '0');
        month = (date.getMonth() + 1).toString().padStart(2, '0');
        year = date.getFullYear();

        originUploads = movement.uploads.filter(x => x.MovementUpload.locationType === 'ORIGIN');
        destinationUploads = movement.uploads.filter(x => x.MovementUpload.locationType === 'DESTINATION');
    }

    return (
        <Card className='single-entity-page'>
            {movement && (
                <>
                    <PageHeader
                        {...props}
                        breadcrumbs={[
                            { label: t(`layout.movements`), path: props.basePath },
                            {
                                label: `${dateNb}.${month}.${year}`,
                                path: props.basePath + `?filter=%7B"updatedAt"%3A"${year}-${month}-${dateNb}"%7D`
                            },
                            { label: movement.origin.name }
                        ]}
                        noBackground
                    />
                    <div className='movement-details'>
                        <div className='locations'>
                            <h2>{t('movements.origin')}</h2>
                            <div className='location-name origin'>
                                <a
                                    className={`name ${!movement.origin.active && 'archived'}`}
                                    href={movement.origin.active && `/#/locations/${movement.origin.id}/show`}
                                >
                                    {movement.origin.name}
                                </a>
                                {originUploads.length > 0 && movement.origin.type === 'CTR' && (
                                    <button
                                        className='view-pictures'
                                        onClick={() => {
                                            setOpenOriginPhotos(true);
                                        }}
                                    >
                                        ({t('movements.view-pictures')})
                                    </button>
                                )}
                            </div>
                            <h2>{t('movements.destination')}</h2>
                            <div className='location-name destination'>
                                <a
                                    className={`name ${!movement.destination.active && 'archived'}`}
                                    href={movement.destination.active && `/#/locations/${movement.destination.id}/show`}
                                >
                                    {movement.destination.name}
                                </a>
                                {destinationUploads.length > 0 && movement.destination.type === 'CTR' && (
                                    <button
                                        className='view-pictures'
                                        onClick={() => {
                                            setOpenDestinationPhotos(true);
                                        }}
                                    >
                                        ({t('movements.view-pictures')})
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className='details'>
                            <span className='updated-at'>{`${dateNb}.${month}.${year}`}</span>
                            <span className='agent'>
                                {t('movements.agent') +
                                    t('layout.colon') +
                                    movement.user.firstname +
                                    ' ' +
                                    movement.user.lastname}
                            </span>
                            <span className='id'>
                                {t('users.badge-number') + t('layout.colon') + '#' + movement.user.badge}
                            </span>
                        </div>
                    </div>
                    <MyList
                        {...props}
                        resource='materials'
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={{ '$movements%id$': movement.id }}
                        filters={null}
                        actions={null}
                        isRowSelectable={() => {
                            return false;
                        }}
                        emptyDatagrid={t('movements.no-materials')}
                        empty={false}
                    >
                        <ReferenceField
                            source='categoryId'
                            reference='materialCategories'
                            sortable
                            label={t('materials.category')}
                            link={false}
                        >
                            <TextFieldPink source='name' />
                        </ReferenceField>
                        <TextFieldImage source='name' label={t('materials.material')} sortable />
                        <MaterialQuantityField
                            movement={movement}
                            label={t('materials.quantity')}
                            source='movements.MovementMaterial.quantity'
                            sortable
                        />
                    </MyList>
                    <MovementUploadsDialog
                        open={openOriginPhotos}
                        setOpen={() => setOpenOriginPhotos()}
                        uploads={originUploads}
                        movement={movement}
                    />
                    <MovementUploadsDialog
                        open={openDestinationPhotos}
                        setOpen={() => setOpenDestinationPhotos()}
                        uploads={destinationUploads}
                        movement={movement}
                    />
                </>
            )}
        </Card>
    );
};
