// in src/ResetPasswordPage.js
import { t } from 'i18next';
import * as React from 'react';
import { useState } from 'react';
import { Notification, useNotify } from 'react-admin';

import { Button } from './react-admin-components/Buttons';
import "../style/components/reset-password-page.scss";

export const ResetPasswordForm = () => {
    const [badge, setBadge] = useState();
    const [inputError, setInputError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const notify = useNotify();
    const submit = e => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/resetPassword/${badge}`, { method: "PUT" })
            .then(async response => {
                const res = await response.json();
                if (response.status === 200) {
                    setEmailSent(true);
                } else {
                    res.message && notify(t(res.messageKey));
                    setInputError(true);
                }
            })
    };

    return (
        <section id="reset_password_page">
            {!emailSent ? (
                <div className="content">
                    <h1>{t('reset-password.reset-ask')}</h1>
                    <span className="subtitle">{t('reset-password.enter-badge')}</span>
                    <form id="login_form" onSubmit={submit}>
                        <div className={`input large-input ${inputError && "error"}`}>
                            <label><span>{t('users.badge-number')}</span></label>
                            <input
                                name="badge_id"
                                type="number"
                                value={badge}
                                onChange={e => setBadge(e.target.value)}
                                placeholder={t('login.enter-badge')}
                            />
                        </div>
                        <Button value={t('reset-password.send')} type="submit" />
                    </form>
                    <a href='/'>{t('reset-password.login')}</a>
                </div>
            ) : (
                <div className="content">
                    <h1>{t('reset-password.email-sent')}</h1>
                    <span>{t('reset-password.can-close')}</span>
                </div>
            )}
            <Notification />
        </section>
    );
};

export const NewPasswordForm = () => {
    const notify = useNotify();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const submit = e => {
        e.preventDefault();
        const hash = window.location.hash.split('=');
        if (!password || !confirmPassword) {
            notify(t('users.enter-password'), { undoable: false });
            return;
        } else if (password !== confirmPassword) {
            notify(t('users.identical-password'), { undoable: false });
            return;
        } else if (!hash[1] || hash[0].substring('zkr') === -1) {
            notify(t('reset-password.expired'))
        }
        fetch(`${process.env.REACT_APP_API_URL}/setNewPassword`, { method: "PUT", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ token: hash[1], password: password }) })
            .then(async response => {
                const res = await response.json();
                if (response.status === 200) {
                    setPasswordUpdated(true);
                } else {
                    res.messageKey && notify(t(res.messageKey));
                }
            })
    };

    return (
        <section id="reset_password_page">
            {!passwordUpdated ? (
                <div className="content">
                    <h1>{t('reset-password.create-new')}</h1>
                    <span className="subtitle">{t('reset-password.new-subtitle')}</span>
                    <form id="login_form" onSubmit={submit}>
                        <div className="input large-input">
                            <label><span>{t('users.password')}</span></label>
                            <input
                                name="badge_id"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder={t('users.new-password')}
                            />
                        </div>
                        <div className="input large-input">
                            <label><span>{t('users.password')}</span></label>
                            <input
                                name="badge_id"
                                type="password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                placeholder={t('users.confirm')}
                            />
                        </div>
                        <Button value={t('reset-password.send')} type="submit" />
                    </form>
                    <a href='/#/reset-password'>{t('reset-password.ask-new')}</a>
                    <a href='/'>{t('reset-password.login')}</a>
                </div>
            ) : (
                <div className="content">
                    <h1>{t('reset-password.password-updated')}</h1>
                    <a href='/'>{t('reset-password.login')}</a>
                </div>
            )}
            <Notification />
        </section>
    );
}