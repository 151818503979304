import restProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = restProvider(process.env.REACT_APP_API_URL, httpClient);

const myDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        if (resource === 'locations') {
            params.data.filling = params.data.type === 'LOCAL' ? 'NA' : params.data.filling;
            return dataProvider.create(resource, params);
        } else if (resource === 'uploads' && params.data.formData instanceof FormData) {
            let url = new URL(`${process.env.REACT_APP_API_URL}/${params.data.resource}/${params.data.id}/upload`);
            params.data.afterFinish && url.searchParams.append('afterFinish', params.data.afterFinish);
            params.data.locationType && url.searchParams.append('locationType', params.data.locationType);

            return httpClient(url, {
                method: 'POST',
                body: params.data.formData
            }).then(({ json }) => ({
                data: { ...json }
            }));
        } else {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
    },
    update: (resource, params) => {
        if (resource === 'materials' && params.data.image) {
            let formData = new FormData();
            Object.keys(params.data).forEach(key => {
                if (key !== 'image') {
                    formData.append(key, params.data[key]);
                } else {
                    formData.append('image', params.data.image.rawFile);
                }
            });

            return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}`, {
                method: 'PUT',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
        } else if (resource === 'locations') {
            params.data.filling = params.data.type === 'LOCAL' ? 'NA' : params.data.filling;
            return dataProvider.update(resource, params);
        } else if (resource === 'defectiveMaterials' && params.data.state === 'REPAIRED') {
            const destinationId = params.data.destinationId;
            return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}/repaired/${destinationId}`, {
                method: 'PUT'
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
        } else if (resource === 'defectiveMaterials' && params.data.state === 'DISCARDED') {
            return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}/discarded`, {
                method: 'PUT'
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
        } else if (resource === 'defectiveMaterials' && params.data.process) {
            return httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}/process`, {
                method: 'PUT'
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
        } else {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
    },
    delete: (resource, params) => {
        if (resource === 'uploads' && 'LocationUpload' in params.previousData) {
            return httpClient(
                `${process.env.REACT_APP_API_URL}/locations/${params.previousData.LocationUpload.locationId}/upload/${params.id}`,
                {
                    method: 'DELETE'
                }
            ).then(({ json }) => ({
                data: { id: json.id }
            }));
        } else if (resource === 'uploads' && 'MovementUpload' in params.previousData) {
            return httpClient(
                `${process.env.REACT_APP_API_URL}/movements/${params.previousData.MovementUpload.movementId}/upload/${params.id}`,
                {
                    method: 'DELETE'
                }
            ).then(({ json }) => ({
                data: { id: json.id }
            }));
        } else {
            // fallback to the default implementation
            return dataProvider.delete(resource, params);
        }
    }
};

export default myDataProvider;
