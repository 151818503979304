import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFR from "./i18n/fr.json";

// the translations
const resources = {
    fr: {
        translation: translationFR,
    },
};

const detection_options = {
    order: ["navigator"],
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: detection_options,
        resources,
        fallbackLng: "fr",

        keySeparator: ".", // to support nested translations

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;