import Card from '@material-ui/core/Card';
import DeleteIcon from '@material-ui/icons/Delete';
import { t } from 'i18next';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
    Create,
    DateField,
    Filter,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SaveContextProvider,
    SearchInput,
    SelectInput,
    SimpleForm,
    TopToolbar,
    isRequired,
    useCreate,
    useDelete,
    useGetIdentity,
    useGetList,
    useGetOne,
    useNotify,
    useRecordContext,
    useRefresh,
    useUpdate
} from 'react-admin';
import { FILLING_LEVEL, LOCATION_TYPES } from '../constants';
import { arrayToChoices } from '../utils';
import { PageHeader } from './PageHeader';
import {
    AddButton,
    ButtonIcon,
    CSVExportButton,
    EditButton,
    Icon,
    InputFileButton,
    MyDeleteButton,
    MySaveButton,
    ViewButton
} from './react-admin-components/Buttons';
import { MovementUploadsDialog, MovementsDialog, MyDialog } from './react-admin-components/Dialog';
import { MyAutoCompleteInput, MyTextInput } from './react-admin-components/Inputs';
import MyList from './react-admin-components/MyList';
import { MyTopToolbar } from './react-admin-components/MyTopToolbar';
import { MyTextField, TextFieldImage, TextFieldPink } from './react-admin-components/TextFields';

const LocationActions = props => {
    return (
        <MyTopToolbar {...props}>
            <CSVExportButton
                {...props}
                exporterDatas={Object.values(props.data)}
                exportFilename={t('layout.locations')}
                exportFormatFunction={formatLocationForExport}
            />
            <AddButton label={t('locations.add')} onClick={() => props.addLocationFunc()} />
        </MyTopToolbar>
    );
};

const formatLocationForExport = (location, _) => {
    return {
        [t('locations.location')]: location.name,
        [t('locations.location-type')]: t(`locations.${location.type}`),
        [t('locations.updated-at')]: location.updatedAt,
        [t('locations.filling-level')]: t(`locations.${location.filling}`),
        [t('locations.comment')]: location.comment
    };
};

const LocationFilters = props => (
    <Filter {...props} className='my-filters'>
        <SearchInput placeholder={t('locations.search')} source='search' alwaysOn className='my-search-field' />
        <SelectInput
            label={t('locations.location')}
            source='type'
            alwaysOn
            choices={arrayToChoices(LOCATION_TYPES.list_choices, 'locations')}
            className='my-filter end'
        />
        <SelectInput
            label={t('locations.filling-level')}
            source='filling'
            alwaysOn
            choices={arrayToChoices(FILLING_LEVEL.display, 'locations')}
            className='my-filter end'
        />
    </Filter>
);

const LocationTypeField = props => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field terciary-text-field`}>
            {t(`locations.${record[source]}`)}
        </span>
    );
};

const TextFieldFilling = props => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field filling-text-field ${record[source]}`}>
            {t(`locations.${record[source]}`)}
        </span>
    );
};

export const LocationList = ({ permissions, ...props }) => {
    const [openCreate, setopencreate] = useState(false);
    const [openEdit, setopenedit] = useState(false);
    const [editRecord, seteditrecord] = useState();
    return (
        <Card>
            <MyList
                {...props}
                filter={{ active: true }}
                filters={<LocationFilters />}
                actions={<LocationActions addLocationFunc={() => setopencreate(true)} />}
                emptyDatagrid={t('layout.no-results')}
                empty={false}
            >
                <TextFieldPink source='name' sortable label={t('locations.location')} textTransform='uppercase' />
                <LocationTypeField source='type' sortable label={t('locations.location-type')} />
                <DateField
                    source='updatedAt'
                    sortable
                    label={t('locations.updated-at')}
                    className='text-field terciary-text-field'
                />
                <TextFieldFilling source='filling' sortable label={t('locations.filling-level')} />
                {permissions === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceField source='plantId' reference='plants' sortable label={t('layout.plant')} link={false}>
                        <MyTextField source='name' />
                    </ReferenceField>
                )}
                <EditButton
                    label={t('layout.actions')}
                    onClick={record => {
                        setopenedit(true);
                        seteditrecord(record);
                    }}
                    className='action-field'
                />
                <ViewButton {...props} className='action-field' />
                <MyDeleteButton
                    {...props}
                    confirmTitle={t('locations.delete')}
                    titleAttribute='name'
                    confirmContent={t('layout.confirm-action')}
                    className='action-field'
                />
            </MyList>
            <MyDialog open={openCreate} setOpen={() => setopencreate()} title={t('locations.add-location')} closeIcon>
                <LocationCreate
                    basePath={props.basePath}
                    resource={props.resource}
                    closeDialog={() => setopencreate(false)}
                />
            </MyDialog>
            <MyDialog open={openEdit} setOpen={() => setopenedit()} title={t('locations.edit-location')} closeIcon>
                <LocationEdit
                    {...props}
                    basePath={props.basePath}
                    resource={props.resource}
                    record={editRecord}
                    closeDialog={() => setopenedit(false)}
                />
            </MyDialog>
        </Card>
    );
};

const LocationCreate = ({ permissions, ...props }) => {
    const notify = useNotify();
    const [type, settype] = useState();
    const { identity, loading } = useGetIdentity();
    if (loading) return null;
    return (
        <Create
            {...props}
            className='create-form'
            onSuccess={() => {
                props.closeDialog();
                notify(t('locations.created'), { undoable: false });
                window.location.reload();
            }}
            onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
        >
            <SimpleForm
                {...props}
                toolbar={<MySaveButton label={t('locations.validate-form')} />}
                submitOnEnter={false}
            >
                <MyTextInput
                    source='name'
                    labelUnderInput
                    label={t('locations.location-id')}
                    placeholder={t('locations.location-placeholder')}
                    validate={isRequired()}
                />
                {permissions === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceInput source='plantId' reference='plants'>
                        <SelectInput optionText='name' />
                    </ReferenceInput>
                )}
                <div className='row'>
                    <SelectInput
                        source='type'
                        onChange={e => settype(e.target.value)}
                        className='input select'
                        choices={arrayToChoices(LOCATION_TYPES.list_choices, 'locations')}
                        validate={isRequired()}
                        label={t('locations.location-type')}
                    />
                    {type !== 'LOCAL' && (
                        <SelectInput
                            source='filling'
                            placeholder={t('locations.filling-level')}
                            choices={arrayToChoices(FILLING_LEVEL.list_choices, 'locations')}
                            className='input select'
                            validate={isRequired()}
                            label={t('locations.filling-level')}
                        />
                    )}
                </div>
                {identity.role === 'ROLE_SUPER_ADMIN' && (
                    <div className='row plant-row'>
                        <ReferenceInput
                            source='plantId'
                            reference='plants'
                            className='input select label-under-input no-margin-bottom'
                            label={t('layout.plant')}
                        >
                            <SelectInput optionText='name' />
                        </ReferenceInput>
                    </div>
                )}
            </SimpleForm>
        </Create>
    );
};

const LocationEdit = ({ permissions, ...props }) => {
    const [type, settype] = useState();
    const { loaded, identity } = useGetIdentity();
    const [update, { loading }] = useUpdate();
    const notify = useNotify();

    const handleSave = useCallback(
        async values => {
            update('locations', props.record.id, values, identity, {
                onSuccess: () => {
                    props.closeDialog();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                    window.location.reload();
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [notify, update, identity, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (!loaded || loading) return null;
    !type && settype(props.record.type);

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                {...props}
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                record={props.record}
                save={handleSave}
            >
                <MyTextInput
                    source='name'
                    labelUnderInput
                    label={t('locations.location-id')}
                    placeholder={t('locations.location-placeholder')}
                    validate={isRequired()}
                />
                {permissions === 'ROLE_SUPER_ADMIN' && (
                    <ReferenceInput source='plantId' reference='plants'>
                        <SelectInput optionText='name' />
                    </ReferenceInput>
                )}
                <div className='row'>
                    <SelectInput
                        source='type'
                        onChange={e => settype(e.target.value)}
                        className='input select'
                        choices={arrayToChoices(LOCATION_TYPES.list_choices, 'locations')}
                        validate={isRequired()}
                        label={t('locations.location-type')}
                    />
                    {type !== 'LOCAL' && (
                        <SelectInput
                            source='filling'
                            choices={arrayToChoices(FILLING_LEVEL.list_choices, 'locations')}
                            className='input select'
                            validate={isRequired()}
                            label={t('locations.filling-level')}
                        />
                    )}
                </div>
            </SimpleForm>
        </SaveContextProvider>
    );
};

// ====================================================================================================
//           SINGLE LOCATION
// ====================================================================================================
const SingleLocationActions = props => {
    const location = props.location;

    return (
        <TopToolbar {...props} className='single-line-actions'>
            <CSVExportButton
                {...props}
                exporterDatas={location.materials}
                exporterAdditionalData={{ location }}
                exportFilename={`${location.name}-${t('layout.materials')}`}
                exportFormatFunction={formatMaterialForExport}
            />
            <ButtonIcon
                src='assets/clock-icon.svg'
                value={t(`locations.${location.type === 'CTR' ? 'location' : 'container'}-history`)}
                onClick={() => props.setOpenMovements()}
                inactive={props.movements.length <= 0}
            />
            {location.type === 'CTR' && (
                <>
                    <InputFileButton
                        src='assets/add-icon.svg'
                        value={t(`locations.add-photo`)}
                        onClick={props.handleFileUpload}
                    />
                    <ButtonIcon
                        src='assets/eye-icon-green.svg'
                        value={t(`locations.see-photos`)}
                        onClick={() => props.setOpenPhotos()}
                        inactive={props.uploads.length <= 0}
                    />
                </>
            )}
            <ButtonIcon
                src='assets/add-icon.svg'
                value={t('materials.add')}
                onClick={() => props.setOpenAddMaterialDialog()}
            />
        </TopToolbar>
    );
};

const formatMaterialForExport = (material, { location }) => {
    if (!material.LocationMaterial) location = material.locations.find(l => l.id === location.id);

    return {
        [t('materials.material')]: material.name,
        [t('materials.category')]: material.category && material.category.name,
        [t('materials.quantity')]: material.LocationMaterial
            ? material.LocationMaterial.quantity
            : location.LocationMaterial.quantity,
        [t('locations.location')]: location.name
    };
};

const SingleLocationFilters = props => {
    const categories = useGetList('materialCategories');
    if (!categories.loaded) return null;

    return (
        <Filter {...props} className='my-filters single-line'>
            <div className='material-name' alwaysOn>
                {props.name && props.name}
            </div>
            <SearchInput
                source='search'
                alwaysOn
                className='my-search-field'
                style={{ width: '250px' }}
                placeholder={t('materials.search')}
                onChange={e => props.setSearchFilter(e.target.value ? true : false)}
            />
            <SelectInput
                source='categoryId'
                alwaysOn
                className='my-filter'
                label={t('materials.category')}
                choices={categories.ids.map(id => {
                    return { id: id, name: categories.data[id].name };
                })}
                onChange={e => props.setCategoryFilter(e.target.value ? true : false)}
            />
        </Filter>
    );
};

const MaterialQuantityField = props => {
    const record = useRecordContext(props);
    let currentLocation = record.locations.length > 0 && record.locations.find(l => l.id === props.location.id);
    return <span className='text-field'>{currentLocation && currentLocation.LocationMaterial.quantity}</span>;
};

export const LocationShow = ({ permissions, ...props }) => {
    const { data } = useGetOne(props.resource, props.id);
    const movementsResource = useGetList('movements', { page: 1, perPage: -1 }, { field: 'updatedAt', order: 'DESC' });
    const [openAddMaterialDialog, setOpenAddMaterialDialog] = useState(false);
    const [openEditMaterialDialog, setOpenEditMaterialDialog] = useState(false);
    const [openMovements, setOpenMovements] = useState(false);
    const [openPhotos, setOpenPhotos] = useState(false);
    const [editRecord, setEditRecord] = useState();
    const [location, setLocation] = useState();
    const [filter, setFilter] = useState({});
    const [lastMovement, setLastMovement] = useState(null);
    const [deleteOne, { loading }] = useDelete();
    const [create] = useCreate();
    const notify = useNotify();
    const movements = movementsResource.data;

    const handleDelete = useCallback(
        async value => {
            const locationMaterial = value.locations.find(l => l.id === location.id).LocationMaterial;
            deleteOne('locationmaterials', locationMaterial.id, locationMaterial, {
                onSuccess: () => {
                    setLocation(location);
                    notify(`${t('layout.element-deleted')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [location]
    );

    const handleFileUpload = event => {
        const formData = new FormData();
        formData.append('image', event.target.files[0]);

        const params = {
            id: lastMovement ? lastMovement.id : location.id,
            formData: formData,
            resource: lastMovement ? 'movements' : 'locations',
            ...(lastMovement && {
                afterFinish: true,
                locationType: lastMovement.originId === location.id ? 'ORIGIN' : 'DESTINATION'
            })
        };

        create(
            'uploads',
            { ...params },
            {
                onSuccess: response => {
                    lastMovement ? setLastMovement(response.data) : setLocation(response.data);
                    notify(`${t('layout.image-upload')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            }
        );
    };

    const refreshUploads = uploads => {
        lastMovement
            ? setLastMovement({ ...lastMovement, uploads: uploads })
            : setLocation({ ...location, uploads: uploads });
    };

    // Await all data to be loaded before returning page
    if (!movementsResource.loaded || loading) return null;

    // Set location state when data is loaded or reset location state when data uploads are loaded
    if ((!location && data) || (location && !location.uploads && data.uploads && data.uploads.length > 0))
        setLocation(data);

    const locationMovements = [];
    Object.values(movements).forEach(
        m => m.finished && (m.originId === props.id || m.destinationId === props.id) && locationMovements.push(m)
    );

    if (locationMovements[0] && !lastMovement) setLastMovement(locationMovements[0]);

    let uploads = [];

    // If location has uploads, show them
    if (location && location.uploads && location.uploads.length > 0) {
        uploads = location.uploads;
    }

    // If location has movements, show uploads from last movement (override previous uploads)
    if (lastMovement && location.type === 'CTR') {
        if (lastMovement.originId === location.id) {
            uploads = lastMovement.uploads.filter(u => u.MovementUpload.locationType === 'ORIGIN');
        } else if (lastMovement.destinationId === location.id) {
            uploads = lastMovement.uploads.filter(u => u.MovementUpload.locationType === 'DESTINATION');
        }
    }

    return (
        <div className='single-entity-page'>
            {location && (
                <>
                    <PageHeader
                        {...props}
                        breadcrumbs={[
                            { label: t(`layout.locations`), path: props.basePath },
                            {
                                label: t(`locations.${location.type}`),
                                path: props.basePath + `?filter=%7B"type"%3A"${location.type}"%7D`
                            },
                            { label: location.name }
                        ]}
                    />
                    <MyList
                        {...props}
                        resource='materials'
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={{ '$locations%id$': location.id }}
                        filters={
                            <SingleLocationFilters
                                {...props}
                                name={location.name}
                                setCategoryFilter={value => setFilter({ ...filter, category: value })}
                                setSearchFilter={value => setFilter({ ...filter, search: value })}
                            />
                        }
                        actions={
                            <SingleLocationActions
                                {...props}
                                resource='materials'
                                sort={{ field: 'name', order: 'ASC' }}
                                filter={{ '$locations%id$': location.id }}
                                location={location}
                                movements={locationMovements}
                                uploads={uploads}
                                setOpenAddMaterialDialog={() => setOpenAddMaterialDialog(true)}
                                setOpenMovements={() => locationMovements.length > 0 && setOpenMovements(true)}
                                setOpenPhotos={() => setOpenPhotos(true)}
                                handleFileUpload={event => handleFileUpload(event)}
                            />
                        }
                        emptyDatagrid={
                            filter.search
                                ? t('locations.no-material-found')
                                : location.type === 'CTR'
                                ? t('locations.empty-ctr')
                                : t('locations.empty')
                        }
                        empty={false}
                    >
                        <TextFieldImage source='name' sortable label={t('materials.material')} bold />
                        <ReferenceField
                            source='categoryId'
                            reference='materialCategories'
                            sortable
                            label={t('materials.category')}
                            link={false}
                        >
                            <TextFieldPink source='name' />
                        </ReferenceField>
                        <MaterialQuantityField
                            source={'locations.LocationMaterial.quantity'}
                            location={location}
                            sortable
                            label={t('materials.quantity')}
                        />
                        <EditButton
                            label={t('layout.actions')}
                            onClick={record => {
                                setOpenEditMaterialDialog(true);
                                setEditRecord(record);
                            }}
                            className='action-field'
                        />
                        <ViewButton res='materials' className='action-field' />
                        <Icon
                            icon={<DeleteIcon className='icon-primary' />}
                            onClick={handleDelete}
                            className='action-field'
                        />
                    </MyList>
                    <MyDialog
                        open={openAddMaterialDialog}
                        setOpen={() => setOpenAddMaterialDialog()}
                        title={t('materials.add-material')}
                        closeIcon
                    >
                        <AddLocationMaterial
                            {...props}
                            record={location}
                            closeDialog={() => setOpenAddMaterialDialog(false)}
                        />
                    </MyDialog>
                    <MyDialog
                        open={openEditMaterialDialog}
                        setOpen={() => setOpenEditMaterialDialog()}
                        title={t('materials.edit-material')}
                        closeIcon
                    >
                        <EditLocationMaterial
                            {...props}
                            record={editRecord}
                            location={location}
                            closeDialog={() => setOpenEditMaterialDialog(false)}
                        />
                    </MyDialog>
                    <MovementsDialog
                        setOpen={() => setOpenMovements()}
                        open={openMovements}
                        movements={locationMovements}
                    />
                    {location.type === 'CTR' && (
                        <MovementUploadsDialog
                            open={openPhotos}
                            setOpen={() => setOpenPhotos()}
                            uploads={uploads}
                            movement={lastMovement}
                            refreshUploads={refreshUploads}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const AddLocationMaterial = ({ permissions, ...props }) => {
    const [create, { loading }] = useCreate();
    const refresh = useRefresh();
    const notify = useNotify();
    const record = props.record;

    const handleSave = useCallback(
        async values => {
            const body = { quantity: values.quantity, locationId: record.id, materialId: values.id };
            create('locationmaterials', body, {
                onSuccess: () => {
                    props.closeDialog();
                    refresh();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [record, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (loading) return null;

    const matchSuggestion = (_, choice) => {
        let filtered = true;
        if (choice.locations.length > 0) {
            choice.locations.forEach(l => {
                if (l.id === record.id) filtered = false;
            });
        }
        return filtered;
    };

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                resource='locations'
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                save={handleSave}
            >
                <MyAutoCompleteInput
                    label={t('materials.reference')}
                    source='id'
                    reference='materials'
                    perPage={50}
                    matchSuggestion={matchSuggestion}
                    validate={isRequired()}
                />
                <NumberInput
                    source='quantity'
                    className='input label-under-input'
                    min={1}
                    step={1}
                    validate={isRequired()}
                />
            </SimpleForm>
        </SaveContextProvider>
    );
};

const EditLocationMaterial = ({ permissions, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [update, { loading }] = useUpdate();
    const { record, location } = props;

    const handleSave = useCallback(
        async values => {
            const body = values['LocationMaterial'];
            const oldValue = values.locations.find(l => l.id === location.id).LocationMaterial;

            update('locationmaterials', body.id, body, oldValue, {
                onSuccess: () => {
                    props.closeDialog();
                    refresh();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [location, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (loading) return null;

    record['LocationMaterial'] =
        record.locations.length > 0 && record.locations.find(l => l.id === location.id).LocationMaterial;

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                resource='materials'
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                save={handleSave}
                record={record}
            >
                <MyTextInput source='name' label={t('materials.reference')} disabled />
                <NumberInput
                    source='LocationMaterial.quantity'
                    className='input label-under-input'
                    min={1}
                    step={1}
                    validate={isRequired()}
                    label={t('materials.quantity')}
                />
            </SimpleForm>
        </SaveContextProvider>
    );
};
