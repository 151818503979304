import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import "./i18n";

Sentry.init({
	dsn: "https://b8de4527d85c44c3a4a24539a481da0f@o4504039593541632.ingest.sentry.io/4504129790541824",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.1,
	enabled: process.env.NODE_ENV !== 'development',
	// debug: true
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
