import { useListPaginationContext } from "react-admin";
import { RANGE_LIMITS } from "../constants";
import '../style/components/range-limiter.scss';

export const RangeLimiter = (props) => {
    const { setPerPage } = useListPaginationContext(props);
    return (
        <div {...props} className="range-selector-container" alwaysOn>
            Voir
            <select name='range' id="range-select" defaultValue={RANGE_LIMITS[0]} onChange={(e) => setPerPage(e.target.value)}>
                {RANGE_LIMITS.map(limit => (<option value={limit} key={limit}>{limit}</option>))}
            </select>
            entrées
        </div>
    );
}