import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useRef } from 'react';
import {
    BulkExportButton,
    CreateButton,
    DeleteWithConfirmButton,
    ExportButton,
    SaveButton,
    useDataProvider,
    useListContext,
    useNotify,
    useRecordContext,
    useResourceContext
} from 'react-admin';
import '../../style/components/react-admin-components/buttons.scss';
import { customExporter } from '../../utils';

export const ViewButton = props => {
    const record = useRecordContext(props);
    const contextResource = useResourceContext();
    const resource = props.res ? props.res : contextResource;
    return (
        <a className='view-button' record={record} icon={<VisibilityIcon />} href={`#/${resource}/${record.id}/show`}>
            <img src={props.icon ? props.icon : 'assets/eye-icon.svg'} alt='View icon' />
        </a>
    );
};

ViewButton.propTypes = {
    record: PropTypes.object,
    basePath: PropTypes.string
};

export const MyDeleteButton = props => {
    const { confirmTitle, confirmContent, record, titleAttribute } = props;
    return (
        <DeleteWithConfirmButton
            {...props}
            className='my-delete-button'
            icon={<DeleteIcon />}
            label=''
            confirmTitle={`${confirmTitle}${titleAttribute && t('layout.colon') + record[titleAttribute]}`}
            confirmContent={<span className='content-text'>{confirmContent}</span>}
        />
    );
};

MyDeleteButton.propTypes = {
    confirmTitle: PropTypes.string.isRequired,
    confirmContent: PropTypes.string.isRequired,
    titleAttribute: PropTypes.string.isRequired
};

export const CSVExportButton = props => {
    const { onClick, sort, exporterDatas, exporterAdditionalData, exportFilename, exportFormatFunction } = props;
    const { filter, filterValues, currentSort, total } = useListContext(props);
    const resource = useResourceContext(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = useCallback(
        event => {
            dataProvider
                .getList(resource, {
                    sort: currentSort || sort,
                    filter: filter ? { ...filterValues, ...filter } : filterValues,
                    pagination: { page: 1, perPage: total }
                })
                .then(({ data }) => {
                    customExporter(
                        data,
                        props.selectedIds,
                        exporterAdditionalData,
                        exportFilename,
                        exportFormatFunction
                    );
                })
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            currentSort,
            dataProvider,
            filter,
            filterValues,
            notify,
            onClick,
            resource,
            sort,
            total,
            props,
            exporterAdditionalData,
            exportFilename,
            exportFormatFunction
        ]
    );

    return props.selectedIds && props.selectedIds.length <= 0 ? (
        <ExportButton
            {...props}
            className={`my-export-button ${Object.keys(props.data).length <= 0 && 'inactive'}`}
            label={t('layout.csv-export-all')}
            icon={<img src='assets/download-icon.svg' alt='Download icon' />}
            exporter={handleClick}
        />
    ) : (
        <BulkExportButton
            {...props}
            className={`my-export-button ${Object.keys(props.data).length <= 0 && 'inactive'}`}
            label={t('layout.csv-export')}
            icon={<img src='assets/download-icon.svg' alt='Download icon' />}
            exporter={() =>
                customExporter(
                    exporterDatas,
                    props.selectedIds,
                    exporterAdditionalData,
                    exportFilename,
                    exportFormatFunction
                )
            }
        />
    );
};

export const AddButton = props => (
    <CreateButton
        {...props}
        className='my-add-button'
        icon={<img src='assets/add-icon.svg' alt='Add icon' />}
        onClick={() => props.onClick()}
    />
);

export const ModifyAccount = props => {
    return (
        <div className='modify-container'>
            <SaveButton {...props} className='modify-account' label={t('users.modify-account')} submitOnEnter={false} />
        </div>
    );
};

export const MySaveButton = props => {
    return (
        <SaveButton
            {...props}
            className={`my-button my-save-button ${props.secondStyle ? 'second-style' : ''} ${props.className}`}
            label={props.label}
            submitOnEnter={false}
            type='button'
        />
    );
};

export const Button = props => (
    <button {...props} className={`my-button ${props.secondStyle && 'second-style'}`}>
        <span>{props.value}</span>
    </button>
);

export const ButtonIcon = props => (
    <button
        {...props}
        className={`my-button button-icon ${props.secondStyle ? 'second-style' : ''} ${
            props.inactive ? 'inactive' : ''
        }`}
    >
        <img src={props.src} alt='Icon' />
        <span>{props.value}</span>
    </button>
);

export const Icon = props => {
    return (
        <button
            {...props}
            className={props.customClass ? props.customClass : 'icon-button'}
            onClick={() => props.onClick(props.record)}
        >
            {props.src ? <img src={props.src} alt={props.alt} /> : props.icon && props.icon}
        </button>
    );
};

export const UpdateDefectiveMaterialButton = props => {
    return <Icon src='assets/recycle-icon.svg' alt='Update' onClick={() => props.onClick(props.record)} />;
};

export const EditButton = props => {
    return (
        <Icon
            {...props}
            src='assets/edit-icon.svg'
            alt='Edit'
            onClick={() => {
                props.onClick(props.record);
            }}
        />
    );
};

export const InputFileButton = props => {
    const hiddenFileInput = useRef(null);

    return (
        <>
            <input
                type='file'
                ref={hiddenFileInput}
                onChange={event => props.onClick(event)}
                style={{ display: 'none' }}
            />
            <ButtonIcon {...props} onClick={() => hiddenFileInput.current.click()} />
        </>
    );
};
