import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, TextField } from 'react-admin';
import '../../style/components/react-admin-components/text-fields.scss';
import { useState } from "react";

// import AWS from 'aws-sdk';

export const TextFieldPink = (props) => {
    return <TextField {...props} className={`text-field pink-text-field ${props.textTransform}`} />;
}

export const TextFieldTerciary = (props) => {
    return <TextField {...props} className="text-field terciary-text-field" />;
}

export const MyTextField = (props) => {
    return <TextField {...props} className="text-field" />;
}


export const TextFieldImage = (props) => {
    const { source, bold } = props;
    const record = useRecordContext(props);
    const [data, setData] = useState();
    if (record.uploadId && !data) {
        const request = new Request(`${process.env.REACT_APP_API_URL}/uploads/${record.uploadId}`, { credentials: 'include' });
        fetch(request)
            .then((response) => {
                return response.json();
            }).then(d => {
                Promise.resolve(d).then(res => setData(res));
            });
    }
    return (
        <div className="text-field-image">
            <img src={record.uploadId && data ? process.env.REACT_APP_S3_PUBLIC_URL + data.key : 'assets/ILLUSTRATIONS-APPLICATION-ONET-IMAGE-PAR-DEFAUT.svg'} alt="Field" />
            <span className={`text-field ${bold && 'bold'}`}>{record[source]}</span>
        </div>
    );
}

TextFieldImage.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    imageSrc: PropTypes.string,
    bold: PropTypes.bool,
};
