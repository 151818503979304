import { t } from 'i18next';
import { useGetIdentity } from 'react-admin';
import '../style/components/page-header.scss';
import { ButtonIcon } from './react-admin-components/Buttons';

export const PageHeader = (props) => {
    const user = useGetIdentity();
    return (
        <div className={`page-header page-title ${props.noBackground && "no-background"}`} >
            <div className='breadcrumb'>{props.breadcrumbs && props.breadcrumbs.map((crumb, i) => crumb && <div className='crumb' key={i}><a href={crumb.path && '#' + crumb.path}>{crumb.label}</a><img src='assets/chevron-right.svg' alt='Chevron right' /></div>)}</div>
            <span className='plant-name'>{user.identity && user.identity.plant.name}</span>
        </div>
    )
}

export const UserPageHeader = (props) => {
    const user = useGetIdentity();
    return (
        <div className={`page-header user-page-header page-title`} >
            <div className="content">
                <div className="col">
                    <div className='breadcrumb'>{props.breadcrumbs && props.breadcrumbs.map((crumb, i) => crumb && <div className='crumb' key={i}><a href={crumb.path && '#' + crumb.path}>{crumb.label}</a><img src='assets/chevron-right.svg' alt='Chevron right' /></div>)}</div>
                    <span className='plant-name'>{user.identity && user.identity.plant.name}</span>
                </div>
                <div className="col">
                    <ButtonIcon {...props} src="assets/add-icon.svg" value={t('users.add')} onClick={() => props.onAddClick()} />
                </div>
            </div>
        </div>
    )
}