import React, { useCallback, useMemo, useState } from "react";
import {
    TextInput,
    SimpleForm,
    required,
    useNotify,
    SaveContextProvider,
    useGetIdentity,
    useUpdate,
    SelectInput,
    ReferenceInput,
} from "react-admin";
import Card from "@material-ui/core/Card";
import { PageHeader } from "./PageHeader";
import { t } from "i18next";
import { ModifyAccount } from "./react-admin-components/Buttons";
import { EditPasswordDialog } from "./react-admin-components/Dialog";
import '../style/components/profile.scss';
import { arrayToChoices } from "../utils";
import { ROLES } from "../constants";
import { RoleTextInput } from "./Users";

export const Profile = () => {
    const notify = useNotify();
    const { loaded, identity } = useGetIdentity();

    const [update, { loading }] = useUpdate();
    const [open, setOpen] = useState();

    const handleSave = useCallback(
        async (values) => {
            update("users", identity.id, values, identity, {
                onSuccess: () => {
                    notify(t('layout.changes-saved'), { undoable: false });
                    identity.role = values.role;
                },
                onFailure: (error) => notify(`Error: ${error.message}`, { type: "warning" }),
            });
        },
        [notify, update, identity]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading,
        }),
        [loading, handleSave]
    );

    if (!loaded) return null;

    return (
        <Card id="profile">
            <PageHeader breadcrumbs={[{ label: t('layout.user') }]} />
            <SaveContextProvider value={saveContext} >
                <SimpleForm save={handleSave} record={identity} className="user-profile column" id="profile_form" toolbar={<ModifyAccount />}>
                    <div className="row">
                        <div className="column">
                            <TextInput source="badge" validate={required()} label={t('users.badge-number')} className="input" />
                            <TextInput source="lastname" validate={required()} label={t('users.lastname')} className="input" />
                        </div>
                        <div className="column">
                            {(identity.role === "ROLE_ADMIN" || identity.role === "ROLE_SUPER_ADMIN") ?
                                <SelectInput source="role" validate={required()} choices={arrayToChoices(ROLES[identity.role], "users")} className="input select" />
                                :
                                <RoleTextInput label={t('users.occupied-role')} />
                            }
                            <TextInput source="firstname" validate={required()} label={t('users.firstname')} className="input" />
                        </div>
                    </div>
                    {(identity.role === "ROLE_ADMIN" || identity.role === "ROLE_SUPER_ADMIN") &&
                        <TextInput source="email" label={t('users.email')} className="input" />
                    }
                    {identity.role === 'ROLE_SUPER_ADMIN' && (
                        <div className="row plant-row">
                            <ReferenceInput source="plantId" reference="plants" className="input select" label={t('layout.plant')}>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        </div>
                    )}
                </SimpleForm>
            </SaveContextProvider>
            <div className="reset-container">
                <button className="reset-password-button" onClick={() => setOpen(true)} >{t('login.reset-password')}</button>
            </div>
            <EditPasswordDialog open={open} setOpen={() => setOpen()} user={identity} />
        </Card >
    );
};
