import { t } from 'i18next';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';

export const arrayToChoices = (items, page) => items.map((item) => ({ id: item, name: t(`${page}.${item}`) }));

export const customExporter = (datas, selectedIds, additionalData, exportFilename, formatFunction) => {
    let toExportRecords;
    if (selectedIds && selectedIds.length > 0) {
        // Items selected
        toExportRecords = selectedIds.map((id) => {
            return formatFunction(
                datas.find((data) => data.id === id),
                additionalData
            );
        });
    } else {
        // All items
        toExportRecords = datas.map((data) => {
            return formatFunction(data, additionalData);
        });
    }

    const today = new Date();
    const dateNb = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const year = today.getFullYear();
    const universalBOM = '\uFEFF'; // Force Excel to open the file in UTF-8

    jsonExport(toExportRecords, (err, csv) =>
        downloadCSV(universalBOM + csv, `${exportFilename}-${dateNb}${month}${year}`)
    );
};
