// in src/MyLoginPage.js
import { t } from 'i18next';
import * as React from 'react';
import { useState } from 'react';
import { useNotify, Notification, useLogin } from 'react-admin';
import '../../style/components/react-admin-components/login-page.scss';
import { Button } from './Buttons';

const MyLoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const submit = e => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify(t('login.invalid-credentials'))
        );
    };

    return (
        <section id="login_page">
            <div className="col form">
                <img className='logo' src="assets/elio-logo.svg" alt="élio logo" />
                <h1>{t('login.connection')}</h1>
                <span className="welcome">{t('login.welcome')}</span>
                <form id="login_form" onSubmit={submit}>
                    <div className="input large-input">
                        <label><span>{t('users.badge-number')}</span></label>
                        <input
                            name="username"
                            type="number"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            placeholder={t('login.enter-badge')}
                        />
                    </div>
                    <div className="input large-input">
                        <label><span>{t('login.password')}</span></label>
                        <input
                            name="password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder={t('login.password-placeholder')}
                        />
                    </div>
                    <a href='/#/reset-password'>{t('login.reset-password')}</a>
                    <Button value={t('login.connection')} type="submit" />
                </form>
            </div>
            <div className="col plant">
                <img className='illustration' src='assets/nuclear-plant.svg' alt="Nuclear plant" />
            </div>
            <Notification />
        </section>

    );
};

export default MyLoginPage;