import * as React from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router";
import { HashRouter, Route as HashRoute, Switch } from "react-router-dom";
import { t } from "i18next";
import { Provider } from "react-redux";
import createAdminStore from "./createAdminStore";
import { createHashHistory } from 'history';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import CustomLayout from "./CustomLayout";

import { UserList, UserShow } from "./components/Users"
import { MaterialList, MaterialShow } from "./components/Materials"
import { LocationList, LocationShow } from "./components/Locations"
import { MovementList, MovementShow } from "./components/Movements"
import { DefectiveMaterialList, DefectiveMaterialShow } from "./components/DefectiveMaterials";
import { Profile } from './components/Profile';
import MyLoginPage from "./components/react-admin-components/LoginPage";
import { NewPasswordForm, ResetPasswordForm } from "./components/ResetPassword";
import './style/index.scss';

const customRoutes = [
    <Route exact path="/profile" component={Profile} icon='assets/user-icon.svg' />,
]

const App = () => {
    const history = createHashHistory();

    return (
        <HashRouter>
            <Switch>
                <HashRoute path="/reset-password" >
                    <Provider store={createAdminStore({ authProvider, dataProvider, history, })}>
                        <ResetPasswordForm />
                    </Provider>
                </HashRoute>
                <HashRoute path="/set-new-password">
                    <Provider store={createAdminStore({ authProvider, dataProvider, history, })}>
                        <NewPasswordForm />
                    </Provider>
                </HashRoute>
                <Admin basename="/admin" dataProvider={dataProvider} authProvider={authProvider} customRoutes={customRoutes} layout={CustomLayout} loginPage={MyLoginPage} history={history}>
                    {permissions => [
                        <Resource name="materials" list={MaterialList} show={MaterialShow} options={{ label: t('layout.materials') }} icon='assets/material-icon.svg' />,
                        <Resource name="locations" list={LocationList} show={LocationShow} options={{ label: t('layout.locations') }} icon='assets/location-icon.svg' />,
                        <Resource name="movements" list={MovementList} show={MovementShow} options={{ label: t('layout.movements') }} icon='assets/movement-icon.svg' />,
                        <Resource name="defectiveMaterials" list={DefectiveMaterialList} show={DefectiveMaterialShow} options={{ label: t('layout.defectiveMaterials') }} icon='assets/defective-material-icon.svg' />,
                        <Resource name="users" list={UserList} show={UserShow} options={{ label: t('layout.users') }} icon='assets/team-icon.svg' />,
                        <Resource name="plants" />,
                        <Resource name="locationmaterials" />,
                        <Resource name="materialCategories" />
                    ]}
                </Admin>
            </Switch>
        </HashRouter>
    )
};

export default App;
