import Card from '@material-ui/core/Card';
import { t } from 'i18next';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
    Create,
    FileInput,
    Filter,
    FunctionField,
    ReferenceField,
    ReferenceInput,
    SaveContextProvider,
    SearchInput,
    SelectInput,
    SimpleForm,
    TextInput,
    isRequired,
    useGetIdentity,
    useGetList,
    useGetOne,
    useNotify,
    useRecordContext,
    useUpdate
} from 'react-admin';
import { DEFECTIVE_MATERIAL_STATES } from '../constants';
import '../style/components/defective-materials.scss';
import { arrayToChoices } from '../utils';
import { PageHeader } from './PageHeader';
import { RangeLimiter } from './RangeLimiter';
import {
    ButtonIcon,
    CSVExportButton,
    MySaveButton,
    UpdateDefectiveMaterialButton,
    ViewButton
} from './react-admin-components/Buttons';
import { DefectiveMaterialUploadDialog, MyDialog } from './react-admin-components/Dialog';
import { MyTextInput } from './react-admin-components/Inputs';
import MyList from './react-admin-components/MyList';
import { MyTopToolbar } from './react-admin-components/MyTopToolbar';
import { TextFieldImage, TextFieldPink } from './react-admin-components/TextFields';

const DefectiveMaterialActions = props => {
    return (
        <MyTopToolbar {...props}>
            <CSVExportButton
                {...props}
                exporterDatas={Object.values(props.data)}
                exportFilename={t('layout.defectiveMaterials')}
                exportFormatFunction={formatDefectiveMaterialForExport}
            />
        </MyTopToolbar>
    );
};

const formatDefectiveMaterialForExport = (defectiveMaterial, _) => {
    return {
        [t('materials.material')]: defectiveMaterial.material.name,
        [t('defective-materials.code')]: `#${defectiveMaterial.materialId.slice(0, 4)}`,
        [t('locations.location')]: defectiveMaterial.origin.name,
        [t('defective-materials.state')]: t(`defective-materials.${defectiveMaterial.state}`),
        [t('defective-materials.comment')]: defectiveMaterial.comment,
        [t(
            'movements.agent'
        )]: `${defectiveMaterial.movement.user.firstname} ${defectiveMaterial.movement.user.lastname}`,
        [t('layout.date')]: defectiveMaterial.createdAt
    };
};

const DefectiveMaterialFilter = props => (
    <Filter {...props} className='my-filters'>
        <SearchInput placeholder={t('materials.search')} source='search' alwaysOn className='my-search-field' />
        <RangeLimiter alwaysOn className='my-filter' />
        <SelectInput
            source='state'
            label={t('defective-materials.state')}
            alwaysOn
            choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
            className='my-filter end'
        />
    </Filter>
);

const DMIdField = props => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field lowercase`}>
            #{record[source].slice(0, 4)}
        </span>
    );
};

const DMStateField = props => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <span {...props} className={`text-field dm-state-text-field ${record[source]}`}>
            {t(`defective-materials.${record[source]}`)}
        </span>
    );
};

const LocationField = props => <TextFieldPink {...props} record={props.record[props.target]} source='name' />;

export const DefectiveMaterialList = ({ permissions, ...props }) => {
    const [openEdit, setOpenEdit] = useState();
    const [editRecord, setEditRecord] = useState();

    return (
        <Card>
            <MyList
                {...props}
                sort={{ field: 'createdAt', order: 'DESC' }}
                filters={<DefectiveMaterialFilter />}
                actions={<DefectiveMaterialActions {...props} />}
                emptyDatagrid={t('layout.no-results')}
                empty={false}
            >
                <LocationField
                    source='origin.name'
                    target='origin'
                    label={t('locations.location')}
                    sortable
                    uppercase
                />
                <FunctionField
                    reference='materials'
                    label={t('materials.material')}
                    render={record => record && <TextFieldImage source='name' record={record.material} />}
                    sortable
                    source='material.name'
                    className='text-field terciary-text-field'
                />
                <ReferenceField
                    reference='materials'
                    source='materialId'
                    label={t('defective-materials.code')}
                    sortable
                    link={false}
                >
                    <DMIdField source='id' />
                </ReferenceField>
                <DMStateField source='state' sortable label={t('defective-materials.state')} />
                <FunctionField
                    className='action-field'
                    label={t('layout.actions')}
                    render={record =>
                        (record.state === 'TO_PROCESS' || record.state === 'MAINTENANCE') && (
                            <UpdateDefectiveMaterialButton
                                label={t('layout.actions')}
                                onClick={() => {
                                    setOpenEdit(true);
                                    setEditRecord(record);
                                }}
                            />
                        )
                    }
                />
                <FunctionField
                    className='action-field'
                    render={(record /*(record.state === "TO_PROCESS" || record.state === "MAINTENANCE") &&*/) => (
                        <ViewButton icon='assets/send-icon.svg' record={record} />
                    )}
                />
            </MyList>
            <MyDialog open={openEdit} setOpen={() => setOpenEdit()} title={t('materials.edit-material')} closeIcon>
                <DefectiveMaterialEdit
                    {...props}
                    basePath={props.basePath}
                    resource={props.resource}
                    record={editRecord}
                    closeDialog={() => setOpenEdit(false)}
                />
            </MyDialog>
        </Card>
    );
};

const DefectiveMaterialEdit = ({ permissions, ...props }) => {
    const { loaded, identity } = useGetIdentity();
    const [update, { loading }] = useUpdate();
    const notify = useNotify();
    const locations = useGetList('locations', { page: 1, perPage: 1000 });
    const [state, setState] = useState();

    const handleSave = useCallback(
        async values => {
            update('defectiveMaterials', props.record.id, values, identity, {
                onSuccess: () => {
                    props.closeDialog();
                    notify(`${t('layout.changes-saved')}`, { undoable: false });
                    window.location.reload();
                },
                onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
            });
        },
        [notify, update, identity, props]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            loading
        }),
        [loading, handleSave]
    );

    if (!locations.loaded || !loaded || loading) return null;
    const choices = locations.ids.map(id => {
        return { id: id, name: locations.data[id].name };
    });

    return (
        <SaveContextProvider {...props} className='create-form' value={saveContext}>
            <SimpleForm
                {...props}
                toolbar={<MySaveButton label={t('layout.validate-form')} />}
                submitOnEnter={false}
                record={props.record}
                save={handleSave}
            >
                <MyTextInput
                    source='material.name'
                    labelUnderInput
                    label={t('materials.reference')}
                    placeholder={t('locations.location-placeholder')}
                    validate={isRequired()}
                />
                <div className='row'>
                    <SelectInput
                        source='state'
                        onChange={e => setState(e.target.value)}
                        className='input select label-under-input'
                        choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
                        validate={isRequired()}
                        label={t('defective-materials.state')}
                    />
                    {state === 'REPAIRED' && (
                        <SelectInput
                            source='destinationId'
                            className='input select label-under-input'
                            choices={choices}
                            validate={isRequired()}
                            label={t('locations.location')}
                        />
                    )}
                </div>
            </SimpleForm>
        </SaveContextProvider>
    );
};

// ====================================================================================================
//           SINGLE DEFECTIVE MATERIAL
// ====================================================================================================
export const DefectiveMaterialShow = ({ permissions, ...props }) => {
    const defectiveMaterial = useGetOne(props.resource, props.id);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const { identity, loaded } = useGetIdentity();
    const [update, { loading }] = useUpdate();
    const notify = useNotify();

    const handleProcess = useCallback(async () => {
        update('defectiveMaterials', props.id, { process: true }, identity, {
            onSuccess: () => {
                notify(`${t('layout.changes-saved')}`, { undoable: false });
                window.location.reload();
            },
            onFailure: error => notify(`Error: ${error.message}`, { type: 'warning' })
        });
    }, [notify, update, identity, props]);

    if (defectiveMaterial.loading || loading || !loaded) return null;
    const date = new Date(defectiveMaterial.data.createdAt);
    const dateNb = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const today = new Date();

    return (
        <div id='single-dm-page'>
            <PageHeader
                {...props}
                noBackground
                breadcrumbs={[
                    { label: t(`layout.defectiveMaterials`), path: props.basePath },
                    {
                        label:
                            t('defective-materials.material-intervention-sheet') +
                            '#' +
                            defectiveMaterial.data.id.slice(0, 8)
                    }
                ]}
            />
            <div className='dm-content'>
                <div className='input'>
                    <label>
                        <span>{t('defective-materials.code')}</span>
                    </label>
                    <input disabled value={'#' + defectiveMaterial.data.materialId} />
                </div>
                <div className='input'>
                    <label>
                        <span>{t('defective-materials.material-reference')}</span>
                    </label>
                    <input disabled value={defectiveMaterial.data.material.name} />
                </div>
                <div className='input'>
                    <label>
                        <span>{t('defective-materials.material-location')}</span>
                    </label>
                    <input disabled value={defectiveMaterial.data.origin.name} />
                </div>
                {defectiveMaterial.data.upload && (
                    <div className='show-photo'>
                        <ButtonIcon
                            src='assets/eye-icon-green.svg'
                            value={t(`defective-materials.show-photo`)}
                            onClick={() => setOpenUploadDialog(true)}
                        />
                    </div>
                )}
                <div className='agents'>
                    <div className='col'>
                        <h3>{t('defective-materials.requester')}</h3>
                        <div className='input'>
                            <label>
                                <span>{t('users.lastname')}</span>
                            </label>
                            <input disabled value={defectiveMaterial.data.movement.user.lastname} />
                        </div>
                        <div className='input'>
                            <label>
                                <span>{t('users.firstname')}</span>
                            </label>
                            <input disabled value={defectiveMaterial.data.movement.user.firstname} />
                        </div>
                        <div className='input'>
                            <label>
                                <span>{t('defective-materials.created-at')}</span>
                            </label>
                            <input type='date' disabled value={year + '-' + month + '-' + dateNb} />
                        </div>
                    </div>
                    <div className='col'>
                        <h3>{t('defective-materials.consideration-of-request')}</h3>
                        <div className='input'>
                            <label>
                                <span>{t('users.lastname')}</span>
                            </label>
                            <input
                                disabled
                                value={
                                    defectiveMaterial.data.userId
                                        ? defectiveMaterial.data.personInCharge.lastname
                                        : identity.lastname
                                }
                            />
                        </div>
                        <div className='input'>
                            <label>
                                <span>{t('users.firstname')}</span>
                            </label>
                            <input
                                disabled
                                value={
                                    defectiveMaterial.data.userId
                                        ? defectiveMaterial.data.personInCharge.firstname
                                        : identity.firstname
                                }
                            />
                        </div>
                        <div className='input'>
                            <label>
                                <span>{t('defective-materials.consideration-date')}</span>
                            </label>
                            <input
                                type='date'
                                disabled
                                value={
                                    today.getFullYear() +
                                    '-' +
                                    (today.getMonth() + 1).toString().padStart(2, '0') +
                                    '-' +
                                    today.getDate().toString().padStart(2, '0')
                                }
                            />
                        </div>
                    </div>
                </div>
                <h3>{t('defective-materials.anomaly-title')}</h3>
                <div className='input'>
                    <label>
                        <span>{t('defective-materials.anomaly-type')}</span>
                    </label>
                    <input
                        disabled
                        value={
                            defectiveMaterial.data.anomalyType &&
                            t(`defective-materials.${defectiveMaterial.data.anomalyType}`)
                        }
                    />
                </div>
                <div className='comment'>
                    <h3>{t('defective-materials.comment-title')}</h3>
                    <textarea value={defectiveMaterial.data.comment} disabled />
                </div>
                {defectiveMaterial.data.state === 'TO_PROCESS' && (
                    <div className='send-button'>
                        <ButtonIcon
                            src='assets/send-icon-green.svg'
                            value={t('defective-materials.send-email')}
                            onClick={handleProcess}
                        />
                    </div>
                )}
            </div>
            {defectiveMaterial.data.upload && (
                <DefectiveMaterialUploadDialog
                    open={openUploadDialog}
                    setOpen={() => setOpenUploadDialog()}
                    upload={defectiveMaterial.data.upload}
                />
            )}
        </div>
    );
};

export const DefectiveMaterialCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source='materialId' reference='materials'>
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput source='originId' reference='locations'>
                <SelectInput optionText='name' />
            </ReferenceInput>
            <TextInput multiline source='comment' />
            <FileInput source='image' accept='image/*,.jpg' maxSize={5000000} />
        </SimpleForm>
    </Create>
);
